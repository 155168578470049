import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const Layout = ({ children }) => {
  let navigate = useNavigate();
  const user = useSelector((state) => state?.user);
  useEffect(() => {
    if (!user?.isLoggedIn) {
      navigate("/");
    }
  });
  return (
    <>
      <Header />
      <div className="mainContent">
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
