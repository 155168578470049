import React from "react";
import PhoneVerification from "../Modals/PhoneVerification/PhoneVerification";
import Success from "../Modals/Success/Success";

const Modals = () => {
  return (
    <div>
      <button
        type="button"
        className="btn"
        data-bs-toggle="modal"
        data-bs-target="#successModal"
      >
        Success Modal
      </button>
      <Success />
      <button
        type="button"
        className="btn"
        data-bs-toggle="modal"
        data-bs-target="#phoneVerificationModal"
      >
        Phone Verification
      </button>
      <PhoneVerification />
    </div>
  );
};

export default Modals;
