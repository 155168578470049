import { useEffect } from "react";
import { useSelector } from "react-redux";

const AuthRedirectHome = () => {
  const userData = useSelector((state) => state?.user);
  useEffect(() => {
    if (userData?.isLoggedIn) {
      window.location.href = "/dashboard";
    }
  }, [userData?.isLoggedIn]);
  return;
};

export default AuthRedirectHome;
