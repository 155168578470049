import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getStandardUserDetails,
  addStandardUser,
  updateStandardUserDetails,
  getUserSubscriptionPlanDetails,
} from "../../context/actions/User";
import Information from "../BuildICP/ICPResult/Information";

const AddUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const { id } = useParams();
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const [isEditMode, setIsEditMode] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);


  // Funcion for changes Add user credit on runtime added by Nandini
  const ChangeCredits =(inputval, Field)=>{ 
    var creditsAvailable = document.getElementById(Field+'-available');
    var creditsSpan = document.getElementById(Field+'-span');
    var userInputed = document.getElementById(Field+'Input'); 
    if(inputval != 0){
      var latestValue = creditsAvailable.value - inputval;
      creditsSpan.innerHTML = latestValue;
      // Remove blink class if already present, trigger reflow, and then add it again
      creditsSpan.classList.remove('zoom');
      void creditsSpan.offsetWidth; // Trigger a reflow to restart the animation
      creditsSpan.classList.add('zoom');

      if(latestValue < 0){ 
        userInputed.value = creditsAvailable.value;
        creditsSpan.innerHTML = 0;
        alert("Entered Amount is greater than available amount.")
      } 
    }else{ 
      userInputed.value = ""; 
    }
  }

  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result.status === 200) {
            setGetUserSubscriptionData(result?.data);
          }
        })
      );
    }
  }, []);

  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      const payload = {
        user_id: id,
      };
      dispatch(
        getStandardUserDetails(payload, token, (result) => {
          const data = result;
          if (data) {
            const formattedData = {
              createdBy: data.created_by,
              credit_amount: data.credit_amount,
              prospect_download_credits: data.prospect_download_credits,
              searches_left: data.searches_left,
              created_at: data.created_at,
              updated_at: data.updated_at,
              id: data.user.id,
              email: data.user.email,
              first_name: data.user.first_name,
              last_name: data.user.last_name,
            };
            setUserDetails(formattedData);
            formik.setValues(formattedData);
          }
        })
      );
    }
  }, [id, token, dispatch]);

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    credit_amount: Yup.number()
      .max(
        isEditMode
          ? getUserSubscriptionData?.available_credits +
              userDetails?.credit_amount
          : getUserSubscriptionData?.available_credits,
        "You don't have credit to give staff please buy more credit"
      )
      .moreThan(-1, "Credit amount cannot be negative")
      .required("Credit amount is required"),
    prospect_download_credits: Yup.number()
      .max(
        isEditMode
          ? getUserSubscriptionData?.prospect_credits +
              userDetails?.prospect_download_credits
          : getUserSubscriptionData?.prospect_credits,
        "You don't have credit to give staff please buy more credit"
      )
      .moreThan(-1, "Prospect download credits cannot be negative")
      .required("Prospect download credits are required"),
    searches_left: Yup.number()
      .max(
        isEditMode
          ? getUserSubscriptionData?.searches_left + userDetails?.searches_left
          : getUserSubscriptionData?.searches_left,
        "You don't have enough credit. Please buy more credit."
      )
      .moreThan(-1, "Searches left cannot be negative")
      .required("Searches left are required"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      // credit_amount: 0,
      // prospect_download_credits: 0,
      // searches_left: 0,
      // changed by nandini
      credit_amount: "",
      prospect_download_credits: "",
      searches_left: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        username: values.email,
        credit_amount: values.credit_amount,
        prospect_download_credits: values.prospect_download_credits,
        searches_left: values.searches_left,
        created_by: userId,
      };

      if (isEditMode) {
        dispatch(
          updateStandardUserDetails(
            id,
            { ...payload, id: userId, user: userDetails.id },
            token,
            (responseData) => {
              if (responseData?.status === 200) {
                navigate("/manage-users");
              }
            }
          )
        );
      } else {
        dispatch(
          addStandardUser(payload, token, (responseData) => {
            if (responseData?.status === 200) {
              navigate("/manage-users");
            }
          })
        );
      }
    },
  });

  return (
    <>
      <div className="pageHeadingBlock commonHeading">
        <div className="pageTitle">
          <h3>
            <Link to="/manage-users" className="backToPage">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M9.13073 15.1663L14.8474 20.8829C15.0807 21.1163 15.1925 21.3885 15.1828 21.6996C15.1731 22.0107 15.0516 22.2829 14.8182 22.5163C14.5849 22.7302 14.3127 22.842 14.0016 22.8517C13.6905 22.8614 13.4182 22.7496 13.1849 22.5163L5.4849 14.8163C5.36823 14.6996 5.28559 14.5732 5.23698 14.4371C5.18837 14.301 5.16406 14.1552 5.16406 13.9996C5.16406 13.8441 5.18837 13.6982 5.23698 13.5621C5.28559 13.426 5.36823 13.2996 5.4849 13.1829L13.1849 5.48294C13.3988 5.26905 13.6661 5.16211 13.987 5.16211C14.3078 5.16211 14.5849 5.26905 14.8182 5.48294C15.0516 5.71628 15.1682 5.99336 15.1682 6.31419C15.1682 6.63503 15.0516 6.91211 14.8182 7.14544L9.13073 12.8329H22.1682C22.4988 12.8329 22.7759 12.9447 22.9995 13.1684C23.2231 13.392 23.3349 13.6691 23.3349 13.9996C23.3349 14.3302 23.2231 14.6072 22.9995 14.8309C22.7759 15.0545 22.4988 15.1663 22.1682 15.1663H9.13073Z"
                  fill="#414141"
                />
              </svg>
            </Link>{" "}
            {isEditMode ? "Edit User" : "New User"}
          </h3>
        </div>
        <Information getUserSubscriptionData={getUserSubscriptionData} />
        <div className="buyCredits mb-2">
          <Link to="/credit-payments" className="btn btnPrimaryOutline">
            Buy Additional Credits
          </Link>
        </div>
      </div>

      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper NewSupportTicket">
              <form className="raiseTicket" onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label>
                      First Name <span className="required">&#42;</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      disabled={userDetails}
                      placeholder="Enter First Name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <span className="text-danger">
                        {formik.errors.first_name}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-6">
                    <label>
                      Last Name <span className="required">&#42;</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      disabled={userDetails}
                      placeholder="Enter Last Name"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <span className="text-danger">
                        {formik.errors.last_name}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 col-md-6">
                    <label>
                      Email <span className="required">&#42;</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      disabled={userDetails}
                      placeholder="Enter Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <span className="text-danger">{formik.errors.email}</span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-6">
                    <label>Credit Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      name="credit_amount"
                      placeholder="0"
                      id="creditInput"
                      // placeholder="Enter Credit Amount"
                      value={formik.values.credit_amount}
                      onChange={formik.handleChange}
                      onInput={(e)=>ChangeCredits(e.target.value,'credit')}
                    />
                    {formik.touched.credit_amount &&
                    formik.errors.credit_amount ? (
                      <span className="text-danger">
                        {formik.errors.credit_amount}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 col-md-6">
                    <label>Prospect Download Credits</label>
                    <input
                      type="number"
                      className="form-control"
                      name="prospect_download_credits"
                      placeholder="0"
                      id="prospectInput"
                      // placeholder="Enter Prospect Download Credits"
                      value={formik.values.prospect_download_credits}
                      onChange={formik.handleChange}
                      onInput={(e)=>ChangeCredits(e.target.value,'prospect')}
                    />
                    {formik.touched.prospect_download_credits &&
                    formik.errors.prospect_download_credits ? (
                      <span className="text-danger">
                        {formik.errors.prospect_download_credits}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-6">
                    <label>No. Of Searches</label>
                    <input
                      type="number"
                      className="form-control"
                      name="searches_left"
                      id="searchInput"
                      // placeholder="Enter Searches Left"
                      placeholder="0"
                      value={formik.values.searches_left}
                      onChange={formik.handleChange}
                      onInput={(e)=>ChangeCredits(e.target.value,'search')}
                    />
                    {formik.touched.searches_left &&
                    formik.errors.searches_left ? (
                      <span className="text-danger">
                        {formik.errors.searches_left}
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 form-group formSubmit mt-5">
                  <button type="submit" className="btn">
                    {isEditMode ? "Update" : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
