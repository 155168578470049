import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import BackLink from "../../../components/BackLink";
import WelcomeImageBlock from "../../../components/WelcomeImageBlock";
import {
  update_email_otp_verification,
  resendOTPEmail,
} from "../../../context/actions/User";

import OtpInput from "react-otp-input";
import Success from "../Modals/Success/Success";
const UpdateEmailVerification = () => {
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [navigateTo, setNavigateTo] = useState("");
  const [validationError, setValidationError] = useState("");
  const [otp, setOtp] = useState("");
  const time = 180;
  const initialTimerValue = localStorage.getItem("otpTimerEndTime")
    ? Math.ceil(
        (localStorage.getItem("otpTimerEndTime") - new Date().getTime()) / 1000
      )
    : time;
  const [timer, setTimer] = useState(initialTimerValue);
  const [isTimerRunning, setIsTimerRunning] = useState(initialTimerValue > 0);
  useEffect(() => {
    let interval;
    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            setIsTimerRunning(false);
            clearInterval(interval);
            localStorage.removeItem("otpTimerEndTime");
            localStorage.removeItem("otp");
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerRunning]);

  useEffect(() => {
    if (isTimerRunning) {
      const timerEndTime = new Date().getTime() + timer * 1000;
      localStorage.setItem("otpTimerEndTime", timerEndTime);
      localStorage.setItem("otp", otp);
    }
  }, [timer, otp, isTimerRunning]);

  const onSubmit = () => {
    const payload = {
      user_id: state.user_id,
      otp: otp,
      email: state?.email,
    };
    dispatch(
      update_email_otp_verification(payload, token, (res) => {
        if (res.status === 200) {
          setModal(true);
          setMessage(res.message);
          setNavigateTo("settings");
          clearOtpExpiry();
        } else if (res.status === 400) {
          setModal(true);
          setMessage(res.message);
          setOtp("");
        }
      })
    );
  };

  const onClose = () => {
    setModal(false);
    if (navigateTo === "settings") {
      navigate("/settings");
      clearOtpExpiry();
    }
  };

  const onResendOtp = () => {
    const payload = { email: state?.email, user_id: state.user_id };
    dispatch(
      resendOTPEmail(payload, (res) => {
        if (res) {
          setOtp("");
          setTimer(time);
          setIsTimerRunning(true);
          localStorage.removeItem("otpTimerEndTime");
          localStorage.removeItem("otp");
        }
      })
    );
  };

  const handleOtpChange = (otp) => {
    // Check for non-numeric characters
    if (!/^\d+$/.test(otp)) {
      setValidationError("Please enter only numbers");
    } else {
      setValidationError("");
    }
    // Filter out non-numeric characters
    const filteredOtp = otp.replace(/\D/g, "");
    setOtp(filteredOtp);
  };

  const clearOtpExpiry = () => {
    setTimer(time);
    setIsTimerRunning(false);
    localStorage.removeItem("otpTimerEndTime");
    localStorage.removeItem("otp");
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <div className="verificationPage authPages">
      <div className="container-fluid">
        <div className="siteLogo">
          <img src={Logo} className="logoImage" alt="" />
        </div>
        <div className="row align-items-center">
          <WelcomeImageBlock />
          <div className="col-12 col-md-6">
            <BackLink type="update" />
            <div className="formWrapper">
              {isTimerRunning && (
                <div style={{ color: "green", textAlign: "center" }}>
                  OTP Expires in: {formatTime(timer)}
                </div>
              )}
              {!isTimerRunning && !modal && (
                <div style={{ color: "red", textAlign: "center" }}>
                  OTP Expired{" "}
                </div>
              )}
              <h2>Enter Email Verification Code</h2>
              <p className="smText text-center">
                We’ve sent a code to {state?.email}
              </p>
              <div className="verificationForm authForm">
                <OtpInput
                  value={otp}
                  onChange={handleOtpChange} // Use the handler
                  numInputs={4}
                  separator={<span>-</span>}
                  // Provide a renderInput function to render each input field
                  renderInput={(inputProps, index) => (
                    <input
                      value={inputProps.value}
                      {...inputProps}
                      className="form-control otp-input"
                      autoFocus={index === 0 ? true : undefined}
                      style={{
                        border: "none",
                        borderBottom: "1px solid gray",
                        // width: "0.1rem", // Adjust width as needed
                        margin: "0 0.5rem",
                        fontSize: "1.5rem",
                        textAlign: "center",
                        display: "inline-block", // Ensures the input is treated as a block element
                        verticalAlign: "middle", // Vertically aligns the input within its container
                      }}
                    />
                  )}
                />
                {validationError && (
                  <p className="text-danger">{validationError}</p>
                )}

                <div className="accountInfo mt-4">
                  <p className="m0 text-center">
                    <button
                      type="submit"
                      className="resend-link"
                      onClick={onSubmit}
                      disabled={!otp || validationError}
                    >
                      Verify
                    </button>
                  </p>
                </div>
              </div>
              <div className="accountInfo">
                <p className="m0 text-center">
                  Didn't receive the verification OTP?{" "}
                  {/* <button className="resend-link" onClick={onResendOtp}>
                    Resend OTP
                  </button> */}
                  <a href="javascript:void(0);" onClick={onResendOtp}>
                    Resend OTP
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <Success
          showModal={modal}
          onClose={onClose}
          message={message}
          btnName="Ok"
        />
      )}
    </div>
  );
};

export default UpdateEmailVerification;
