import React, { useEffect, useRef, useState } from "react";
import Table from "react-bootstrap/Table";
import "./FindProspectResult.scss";
import { Link } from "react-router-dom";
import "rc-slider/assets/index.css";
import unlockIcon from "../../../assets/images/unlockIcon.png";
import { useSelector } from "react-redux";

const FindProspectTable = ({
  columnVisibility,
  selectedRows,
  selectAll,
  handleSelectAll,
  handleCheckboxChange,
  sortedData,
  handleSort,
  sortBy,
  currentPage,
  getUserSubscriptionData,
}) => {
  const tableContainerRef = useRef(null);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const [showScrollBottomButton, setShowScrollBottomButton] = useState(false);
  const pageVisibility = useSelector(
    (state) =>
      state?.userSubscriptionData?.userSubscrptionData?.data
        ?.user_page_visibility
  );

  useEffect(() => {
    const handleScroll = () => {
      if (tableContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          tableContainerRef.current;
        setShowScrollTopButton(scrollTop > 0); // Show when scrolled down
        setShowScrollBottomButton(scrollTop + clientHeight < scrollHeight - 1); // Show when not at bottom
      }
    };

    const scrollListener = () => {
      handleScroll();
    };

    if (tableContainerRef.current) {
      tableContainerRef.current.addEventListener("scroll", scrollListener);
      handleScroll(); // Set initial state
    }

    return () => {
      if (tableContainerRef.current) {
        tableContainerRef.current.removeEventListener("scroll", scrollListener);
      }
    };
  }, []);

  const scrollToTop = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const scrollToBottom = () => {
    if (tableContainerRef.current) {
      const scrollHeight = tableContainerRef.current.scrollHeight;
      const clientHeight = tableContainerRef.current.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;
      tableContainerRef.current.scrollTo({
        top: maxScrollTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {/* getUserSubscriptionData?.user_page_visibility  */}
      {pageVisibility >= currentPage ? (
        <div
          className="tableContentBlock table-responsive"
          ref={tableContainerRef}
        >
          <Table>
            <thead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
              }}
            >
              <tr>
                <th>
                  <div className="checkBox">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </div>
                </th>
                {columnVisibility.firstName === true ? (
                  <th>
                    First Name
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                      onClick={() => handleSort("first_name")}
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                ) : null}

                {columnVisibility.lastName === true ? (
                  <th>
                    Last Name
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                      onClick={() => handleSort("last_name")}
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                ) : null}

                {columnVisibility.jobTitle === true ? (
                  <th>
                    Job Title
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                      onClick={() => handleSort("job_title")}
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                ) : null}

                {columnVisibility.jobFunction === true ? (
                  <th>
                    Job Function
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                      onClick={() => handleSort("jobfunction")}
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                ) : null}

                {columnVisibility.jobLevel === true ? (
                  <th>
                    Job Level
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                      onClick={() => handleSort("joblevel")}
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                ) : null}

                {columnVisibility.companyName === true ? (
                  <th>
                    Company Name
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                      onClick={() => handleSort("company_name")}
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                ) : null}

                {columnVisibility.revenue === true ? (
                  <th>
                    Revenue
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                      onClick={() => handleSort("revenue")}
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                ) : null}

                {columnVisibility.companySize === true ? (
                  <th>
                    Company Size
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                      onClick={() => handleSort("comp_size")}
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                ) : null}
                {columnVisibility.country === true ? <th>Country</th> : null}
              </tr>
            </thead>
            <tbody>
              {sortedData.length > 0 ? (
                sortedData?.map((data, index) => (
                  <tr
                    key={data.id}
                    onClick={() => handleCheckboxChange(data.id)}
                  >
                    <td>
                      <div className="checkBox">
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(data.id)}
                          // onChange={() => handleCheckboxChange(data.id)}
                        />
                      </div>
                    </td>
                    {columnVisibility.firstName === true ? (
                      <td>{data.first_name}</td>
                    ) : null}

                    {columnVisibility.lastName === true ? (
                      <td>{data.last_name}</td>
                    ) : null}

                    {columnVisibility.jobTitle === true ? (
                      <td>{data.job_title}</td>
                    ) : null}

                    {columnVisibility.jobFunction === true ? (
                      <td>{data.jobfunction}</td>
                    ) : null}

                    {columnVisibility.jobLevel === true ? (
                      <td>{data.joblevel}</td>
                    ) : null}

                    {columnVisibility.companyName === true ? (
                      <td>{data.company_name}</td>
                    ) : null}

                    {columnVisibility.revenue === true ? (
                      <td>{data.revenue}</td>
                    ) : null}
                    {columnVisibility.companySize === true ? (
                      <td>{data.comp_size}</td>
                    ) : null}
                    {columnVisibility.country === true ? (
                      <td>{data.country}</td>
                    ) : null}
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan={12}>
                    No Records Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {sortedData?.length > 10 && (
            <span>
              {showScrollTopButton && !showScrollBottomButton && (
                <div className={`scroll ${showScrollTopButton ? "show" : ""}`}>
                  <button onClick={scrollToTop} className="btn-scroll">
                    <i class="pi pi-arrow-up"></i>
                  </button>
                </div>
              )}
            </span>
          )}
          {sortedData?.length > 10 && (
            <div className={`scroll ${showScrollBottomButton ? "show" : ""}`}>
              {showScrollBottomButton && showScrollTopButton && (
                <button onClick={scrollToBottom} className="btn-scroll">
                  <i className="pi pi-arrow-down"></i>
                </button>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="overlayBlock">
          <div className="overlayContent">
            <img src={unlockIcon} alt="" />
            <h6>Unlock More Insights!</h6>
            <p>
              You've hit the {getUserSubscriptionData?.user_page_visibility}
              -page limit. Upgrade now to access more pages!
            </p>
            <Link to="/subscription-plans" className="btn">
              Upgrade Now
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default FindProspectTable;
