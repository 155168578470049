import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/Validations/ComonFunctions";

const DashboradCreditDetails = ({ getUserSubscriptionData }) => {
  return (
    <div className="contentCard creditsCard">
      <div className="creditsContent">
          <div className="row">
            <div className="col-md-4 mt-4 text-center">
            <h5>Available Credits</h5>
            <strong>{getUserSubscriptionData?.available_credits}</strong>
            </div>
            <div className="col-md-4 mt-4 d-flex justify-content-center">
              <Link to="/spending-history" className="btn btnPrimaryOutline">
                View Spending History
              </Link>
            </div>
            <div className="col-md-4 mt-4 d-flex justify-content-center">
              <Link to="/subscription-plans" className="btn">
                Buy Credits
              </Link>
            </div>
          </div>
      </div>
      <div className="recordBlock">
        <div className="row mt-5">
          <div className="col-md-4 d-flex justify-content-center">
            <div className="recordCard text-center">
            <h6 className="record-head">
                {getUserSubscriptionData?.searches_left &&
                  getUserSubscriptionData?.search_credit_assigned && (
                    <>
                      {getUserSubscriptionData?.searches_left}/
                      {getUserSubscriptionData?.search_credit_assigned}
                    </>
                  )}
              </h6>
              <p className="report-text">search left</p>
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-center">
            <div className="recordCard text-center">
            <h6 className="record-head"> {formatDate(getUserSubscriptionData?.start_date)}</h6>
              <p className="report-text">last recharge</p>
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-center">
            <div className="recordCard text-center">
            <h6 className="record-head">{getUserSubscriptionData?.credit_spending}</h6>
              <p className="report-text">credits spent</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboradCreditDetails;
