export const maskPhoneNumber = (phoneNumber) => {
  let maskedPhoneNumber = "";
  if (phoneNumber) {
    const visibleDigits = 2;

    const countryCode = phoneNumber.slice(0, 3);
    const digits = phoneNumber.slice(3);

    const maskedDigits =
      "x".repeat(digits.length - visibleDigits) + digits.slice(-visibleDigits);

    maskedPhoneNumber = countryCode + maskedDigits;
  }
  return maskedPhoneNumber;
};
