import slideImage from "../assets/images/slide-image.jpg";

const WelcomeImageBlock = () => {
  return (
    <div className="col-12 col-md-6">
      <div className="contentSlider">
        <div className="imageBlock">
          <img src={slideImage} className="slideImage" alt="" />
        </div>
        <div className="contentBlock text-center">
          <h2>Welcome Back!</h2>
          <p>
            Elevate your business insights with precision. Log in to discover
            and refine your Ideal Customer Profiles effortlessly.
          </p>
        </div>
      </div>
    </div>
  );
};
export default WelcomeImageBlock;
