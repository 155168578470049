import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./NewSupportTicket.scss";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  createTicket,
  getTicketCategory,
  getTicketSubCategory,
} from "../../../context/actions/User";

const DefaultPriorities = [
  { label: "Low", value: "Low" },
  { label: "Medium", value: "Medium" },
  { label: "High", value: "High" },
];
const NewSupportTicket = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const name = useSelector((state) => state.user.userInfo.first_name);
  const [category, setCategory] = useState(null);
  const [priority, setPriority] = useState(null);
  const [priorityList, setPriorityList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategory, setSubCategory] = useState(null);
  const [priorityWithSubcategory, setPriorityWithSubcategory] = useState([]);
  const [problemDescription, setProblemDescription] = useState("");
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isDefault = queryParams.has("isdefault");

  useEffect(() => {
    if (isDefault) {
      // Fetch categories first
      const fetchCategories = async () => {
        if (categoryList.length === 0) {
          const payload = { userId: userId };
          dispatch(
            getTicketCategory(payload, token, (result) => {
              const convertedCategoryListData = result.category_list.map(
                (item) => ({
                  value: item.category,
                  label: item.category,
                })
              );
              setCategoryList(convertedCategoryListData);
            })
          );
        }
      };

      fetchCategories();
    }
  }, [location.search, categoryList, dispatch, token, userId]);

  useEffect(() => {
    if (categoryList.length > 0 && location.search.includes("isdefault")) {
      const defaultCategory = categoryList.find(
        (cat) => cat.label === "Domain Inclusion"
      );

      if (defaultCategory) {
        setCategory(defaultCategory);
        fetchSubCategories(defaultCategory.value);
      }
    }
  }, [categoryList, location.search]);

  useEffect(() => {
    if (userId) {
      const payload = {
        userId: userId,
      };
      dispatch(
        getTicketCategory(payload, token, (result) => {
          let convertedCategoryListData = result.category_list.map((item) => ({
            value: item.category,
            label: item.category,
          }));

          setCategoryList(convertedCategoryListData);
        })
      );
    }
  }, []);

  useEffect(() => {
    if (subCategory && priorityList.length > 0 && isDefault) {
      const defaultPriority = priorityList.find((p) => p.label === "Medium");
      if (defaultPriority) {
        setPriority(defaultPriority);
      }
    }
  }, [subCategory, priorityList, location.search]);

  const fetchSubCategories = async (selectedCategory) => {
    const payload = {
      userId: userId,
      selectedCategoryName: selectedCategory,
    };

    dispatch(
      getTicketSubCategory(payload, token, (response) => {
        const convertedSubCategoryData = response.sub_category_list.map(
          (item) => ({
            value: item.sub_category,
            label: item.sub_category,
          })
        );
        setPriorityWithSubcategory(response.sub_category_list);
        setSubCategoryList(convertedSubCategoryData);

        // Set default subcategory
        const defaultSubCategory = convertedSubCategoryData.find(
          (sub) => sub.label === "Raise Request to Include Domains"
        );
        if (defaultSubCategory && isDefault) {
          setSubCategory(defaultSubCategory);

          // Set default priority
          const priorities = priorityWithSubcategory?.filter(
            (item) => item.sub_category === defaultSubCategory.value
          );
          if (
            priorities.length > 0 &&
            priorities[0].priority !== null &&
            !isDefault
          ) {
            const combinedPriorities = priorities.flatMap((item) => {
              const priorityArray = Array.isArray(item.priority)
                ? item.priority
                : [item.priority];
              return priorityArray.map((priority) => ({
                label: priority,
                value: priority,
              }));
            });
            setPriorityList(combinedPriorities);

            const defaultPriority = combinedPriorities.find(
              (p) => p.label === "Medium"
            );
            if (defaultPriority) {
              setPriority(defaultPriority);
            }
          } else {
            setPriorityList(DefaultPriorities);
          }
        }
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    if (!category) {
      errors.category = "Category is required";
    }
    if (!subCategory) {
      errors.subCategory = "Sub-Category is required";
    }
    if (!priority) {
      errors.priority = "Priority is required";
    }
    if (!problemDescription) {
      errors.problemDescription = "Problem description is required";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // Prepare payload to send to API
    const payload = {
      category: category.value,
      subCategory: subCategory.value,
      priority: priority.value,
      user_profile_id: userId,
      explaination: problemDescription,
      upload_files: file,
    };
    // Prepare FormData to be sent to the backend
    const formData = new FormData();
    formData.append("category", category.value);
    formData.append("subCategory", subCategory.value);
    formData.append("user_profile_id", userId);
    formData.append("priority", priority.value);

    formData.append("explaination", problemDescription);
    formData.append("upload_files", file);

    dispatch(
      createTicket(formData, token, (responseData) => {
        if (responseData?.status === 200) {
          // Reset form fields after submission
          setCategory("");
          setSubCategory("");
          setProblemDescription("");
          setFile(null);

          // Navigate to support tickets page
          navigate("/support-tickets");
        }
      })
    );
  };
  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      const validImageTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
      ];
      const validDocumentTypes = [
        "application/vnd.ms-excel", // .xls
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "text/csv", // .csv
      ];
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes

      if (
        !validImageTypes.includes(uploadedFile.type) &&
        !validDocumentTypes.includes(uploadedFile.type)
      ) {
        alert(
          "File type not supported. Please upload an image or document file."
        );
        e.target.value = ""; // Reset the input
      } else if (uploadedFile.size > maxSize) {
        alert("File size exceeds 5MB. Please upload a smaller file.");
        e.target.value = ""; // Reset the input
      } else {
        setFile(uploadedFile);
      }
    }
  };

  const truncateFilename = (filename) => {
    const maxLength = 20;
    if (filename.length > maxLength) {
      return filename.substring(0, maxLength - 3) + "...";
    }
    return filename;
  };

  const handleSubcategory = (selectedOption) => {
    setSubCategory(selectedOption);
    setPriority(null);
    const priorities = priorityWithSubcategory?.filter(
      (item) => item.sub_category == selectedOption.value
    );
    if (priorities.length > 0 && priorities[0].priority !== null) {
      const combinedPriorities = priorities.flatMap((item) => {
        const priorityArray = Array.isArray(item.priority)
          ? item.priority
          : [item.priority];
        return priorityArray.map((priority) => ({
          label: priority,
          value: priority,
        }));
      });
      setPriorityList(combinedPriorities);
      setPriority(combinedPriorities[0]);
    }
  };

  return (
    <>
      <div className="pageHeadingBlock SupportTicketsHeading">
        <div className="pageTitle">
          <h3>
            <Link to="/support-tickets" className="backToPage">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M9.13073 15.1663L14.8474 20.8829C15.0807 21.1163 15.1925 21.3885 15.1828 21.6996C15.1731 22.0107 15.0516 22.2829 14.8182 22.5163C14.5849 22.7302 14.3127 22.842 14.0016 22.8517C13.6905 22.8614 13.4182 22.7496 13.1849 22.5163L5.4849 14.8163C5.36823 14.6996 5.28559 14.5732 5.23698 14.4371C5.18837 14.301 5.16406 14.1552 5.16406 13.9996C5.16406 13.8441 5.18837 13.6982 5.23698 13.5621C5.28559 13.426 5.36823 13.2996 5.4849 13.1829L13.1849 5.48294C13.3988 5.26905 13.6661 5.16211 13.987 5.16211C14.3078 5.16211 14.5849 5.26905 14.8182 5.48294C15.0516 5.71628 15.1682 5.99336 15.1682 6.31419C15.1682 6.63503 15.0516 6.91211 14.8182 7.14544L9.13073 12.8329H22.1682C22.4988 12.8329 22.7759 12.9447 22.9995 13.1684C23.2231 13.392 23.3349 13.6691 23.3349 13.9996C23.3349 14.3302 23.2231 14.6072 22.9995 14.8309C22.7759 15.0545 22.4988 15.1663 22.1682 15.1663H9.13073Z"
                  fill="#414141"
                />
              </svg>
            </Link>{" "}
            New Support Ticket
          </h3>
        </div>
      </div>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper NewSupportTicket">
              <h4>Hi {name}, What do you need help with?</h4>
              <form className="raiseTicket">
                <div className="row">
                  <div className="col-12 col-md-4 form-group">
                    <label>
                      Category <span className="required">&#42;</span>
                    </label>

                    <Select
                      value={category}
                      isDisabled={isDefault}
                      onChange={(selectedOption) => {
                        // Set selected category
                        setCategory(selectedOption);
                        setSubCategory(null);
                        setPriority(null);
                        setPriorityList([]);
                        // Fetch sub-categories based on the selected category
                        fetchSubCategories(selectedOption.value);
                      }}
                      isSearchable={true}
                      options={categoryList}
                      placeholder="Select Category"
                    />
                    {errors.category && (
                      <p className="error">{errors.category}</p>
                    )}
                  </div>
                  <div className="col-12 col-md-4 form-group">
                    <div className="multiSelectionLabel">
                      <label>
                        Sub-Category <span className="required">&#42;</span>
                      </label>
                      <Select
                        isDisabled={isDefault}
                        value={subCategory}
                        onChange={(selectedOption) => {
                          handleSubcategory(selectedOption);
                        }}
                        isSearchable={true}
                        options={subCategoryList}
                        placeholder="Select Sub-Category"
                        className="selectWidth100" // Add this class
                      />
                      {errors.subCategory && (
                        <p className="error">{errors.subCategory}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 form-group">
                    <label>
                      Priority <span className="required">&#42;</span>
                    </label>

                    <Select
                      value={priority}
                      isDisabled={isDefault}
                      onChange={(selectedOption) => {
                        setPriority(selectedOption);
                      }}
                      isSearchable={true}
                      options={priorityList}
                      placeholder="Select Priority"
                    />
                    {errors.priority && (
                      <p className="error">{errors.priority}</p>
                    )}
                  </div>
                  <div className="col-12 form-group">
                    <label>
                      Explain the Problem{" "}
                      <span className="required">&#42;</span>
                    </label>
                    <textarea
                      className="form-control"
                      maxlength="200"
                      value={problemDescription}
                      onChange={(e) => setProblemDescription(e.target.value)}
                      placeholder="Message Here"
                    />
                    <p className="max-msg">Max length 200 charecters.</p>
                    {errors.problemDescription && (
                      <p className="error">{errors.problemDescription}</p>
                    )}
                  </div>
                  <div className="col-12 form-group">
                    <label>Upload Files</label>
                    <div className="uploadDocuments">
                      <input
                        className="uploadDocInput"
                        id="userImgUploadInput"
                        type="file"
                        accept="image/*, .xls, .xlsx, .csv"
                        onChange={handleFileChange}
                      />
                      <div className="uploadDocContent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="49"
                          height="34"
                          viewBox="0 0 49 34"
                          fill="none"
                        >
                          <path
                            d="M39.5063 12.9996C38.8205 9.52414 36.9493 6.39456 34.2123 4.14543C31.4754 1.8963 28.0425 0.667168 24.5 0.667969C18.5996 0.667969 13.475 4.0163 10.9229 8.9163C7.92214 9.24058 5.14706 10.6624 3.13087 12.9084C1.11469 15.1545 -0.000350558 18.0664 8.26727e-08 21.0846C8.26727e-08 27.8426 5.49208 33.3346 12.25 33.3346H38.7917C44.4267 33.3346 49 28.7613 49 23.1263C49 17.7363 44.8146 13.3671 39.5063 12.9996ZM28.5833 19.043V27.2096H20.4167V19.043H14.2917L23.7854 9.54922C24.1938 9.14089 24.8267 9.14089 25.235 9.54922L34.7083 19.043H28.5833Z"
                            fill="#414141"
                            fillOpacity="0.2"
                          />
                        </svg>
                        <p className="m-0">
                          {file ? (
                            <>{truncateFilename(file.name)} </>
                          ) : (
                            <>
                              Select/Drop File to upload
                              <span className="m-0">
                                Add a screenshot that describes your problem.
                              </span>
                              <span className="m-0">
                                Supports jpeg, png, gif, bmp, webp, xls, xlsx,
                                csv formats
                              </span>
                              <span className="m-0">Max size 5mb</span>
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="uploadedFile">
                      {file && (
                        <div className="fileDetilsItem supportTicketimg">
                          {file.type.startsWith("image/") ? (
                            <p>
                              <img
                                src={URL.createObjectURL(file)}
                                alt={file.name}
                              />{" "}
                              {truncateFilename(file.name)}
                            </p>
                          ) : (
                            <p>{truncateFilename(file.name)}</p>
                          )}
                          <div
                            className="deleteFile"
                            onClick={() => setFile(null)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                            >
                              <path
                                d="M6.34422 7.39805L1.27112 12.4711C1.13267 12.6096 0.958641 12.6804 0.749025 12.6836C0.539425 12.6868 0.362191 12.616 0.217325 12.4711C0.0724415 12.3263 0 12.1506 0 11.9442C0 11.7378 0.0724415 11.5622 0.217325 11.4173L5.2904 6.34422L0.217325 1.27112C0.0788582 1.13267 0.00802483 0.958642 0.00482483 0.749025C0.00160816 0.539425 0.0724415 0.362191 0.217325 0.217325C0.362191 0.0724415 0.537825 0 0.744225 0C0.950625 0 1.12626 0.0724415 1.27112 0.217325L6.34422 5.2904L11.4173 0.217325C11.5558 0.0788582 11.7298 0.00802483 11.9394 0.00482483C12.149 0.00160816 12.3263 0.0724415 12.4711 0.217325C12.616 0.362191 12.6884 0.537825 12.6884 0.744225C12.6884 0.950625 12.616 1.12626 12.4711 1.27112L7.39805 6.34422L12.4711 11.4173C12.6096 11.5558 12.6804 11.7298 12.6836 11.9394C12.6868 12.149 12.616 12.3263 12.4711 12.4711C12.3263 12.616 12.1506 12.6884 11.9442 12.6884C11.7378 12.6884 11.5622 12.616 11.4173 12.4711L6.34422 7.39805Z"
                                fill="#FF2929"
                              />
                            </svg>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 form-group formSubmit m-0">
                    <button
                      type="submit"
                      className="btn"
                      onClick={handleSubmit}
                    >
                      Raise Ticket
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSupportTicket;
