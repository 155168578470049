import React, { useEffect, useRef } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import { Link } from "react-router-dom";
import "./SpendingHistory.scss";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomTooltip = ({ value, index, ...restProps }) => {
  return (
    <div
      {...restProps}
      style={{
        position: "absolute",
        left: `${restProps.style.left}px`,
        top: "-30px",
      }}
    >
      {value}
    </div>
  );
};

const SpendingHistoryFilter = ({
  filters,
  handleFilterChange,
  handleResetFilters,
  handleApplyFilters,
  handleRangeChange,
  validationError,
  setValidationError,
}) => {
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest(".dropdown-menu")
    ) {
      // Click occurred outside the dropdown menu, close it
      // Call your close dropdown function here
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  // Function to handle changes in start and end dates
  const handleDateChange = (startDate, endDate) => {
    // Check if start date is greater than or equal to end date
    if (moment(startDate).isAfter(endDate)) {
      setValidationError("Start date should be before end date");
    } else {
      setValidationError(""); // Clear validation error if validation passes
    }
    // Call the parent function to update filters
    handleFilterChange("startDate", startDate);
    handleFilterChange("endDate", endDate);
  };

  return (
    <div className="dropdown filterContent" ref={dropdownRef}>
      <div
        className="filterDropdown drop"
        id="filterDropdown"
        data-bs-toggle="dropdown"
        // aria-expanded="false"
        // data-bs-auto-close="false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
            stroke="#414141"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>{" "}
        Filters
      </div>
      <div className="dropdown-menu" aria-labelledby="filterDropdown">
        <div className="dropdownHeading">
          <p>Filter</p>
          <Link to="" onClick={handleResetFilters}>
            Reset
          </Link>
        </div>
        <div className="dropdownContent">
          <div className="cardDrop">
            <p className="title">Date Range</p>
            <div className="rangeInputs">
              <div
                // className="inputGroup m-0"
                onClick={(event) => event.stopPropagation()}
              >
                {/* <input
                  type="date"
                  className="form-control"
                  value={filters.date.startDate}
                  onChange={(e) =>
                    handleDateChange(e.target.value, filters.date.endDate)
                  }
                /> */}
                <DatePicker
                  selected={filters.date.startDate}
                  showIcon
                  onChange={(date) =>
                    handleDateChange(date, filters.date.endDate)
                  }
                  maxDate={new Date()}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                />
              </div>
              <div
                // className="inputGroup m-0"
                onClick={(event) => event.stopPropagation()}
              >
                {/* <input
                  type="date"
                  className="form-control"
                  value={filters.date.endDate}
                  onChange={(e) =>
                    handleDateChange(filters.date.startDate, e.target.value)
                  }
                /> */}
                <DatePicker
                  selected={filters.date.endDate}
                  showIcon
                  onClick={(event) => event.stopPropagation()}
                  onChange={(date) =>
                    handleDateChange(filters.date.startDate, date)
                  }
                  dateFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                  placeholderText="MM/DD/YYYY"
                />
              </div>
            </div>
            {validationError && (
              <div className="error-message">{validationError}</div>
            )}
          </div>
          <div className="cardDrop">
            <p className="title">Task</p>
            <form>
              <div className="row">
                <div className="form-group col-12 col-sm-6">
                  <input
                    type="checkbox"
                    checked={filters.task.includes("ICP")}
                    onChange={() => handleFilterChange("task", "ICP")}
                  />
                  <span>VAIS Downloads</span>
                </div>
                <div className="form-group col-12 col-sm-6">
                  <input
                    type="checkbox"
                    checked={filters.task.includes("ABM")}
                    onChange={() => handleFilterChange("task", "ABM")}
                  />
                  <span>ABM Verification</span>
                </div>
                <div className="form-group col-12 col-sm-6">
                  <input
                    type="checkbox"
                    checked={filters.task.includes("LAL")}
                    onChange={() => handleFilterChange("task", "LAL")}
                  />
                  <span>LAL Generation</span>
                </div>
                <div className="form-group col-12 col-sm-6">
                  <input
                    type="checkbox"
                    checked={filters.task.includes("Prospect")}
                    onChange={() => handleFilterChange("task", "Prospect")}
                  />
                  <span>Prospects Downloaded</span>
                </div>
                {/* <div className="form-group col-12 col-sm-6">
                  <input
                    type="checkbox"
                    checked={filters.task.includes("Searches")}
                    onChange={() => handleFilterChange("task", "Searches")}
                  />
                  <span>Searches</span>
                </div> */}
                <div className="form-group col-12 col-sm-6">
                  <input
                    type="checkbox"
                    checked={filters.task.includes("Credits Purchased")}
                    onChange={() =>
                      handleFilterChange("task", "Credits Purchased")
                    }
                  />
                  <span>Credits Purchased</span>
                </div>
              </div>
            </form>
          </div>
          <div className="cardDrop">
            <p className="title">Credits Spent</p>
            <div className="rangeSlides">
              <Slider
                range
                min={0}
                max={100}
                // value={rangeValue}
                // onChange={handleRangeChange}
                onChange={(values) => handleRangeChange("credit_spent", values)}
                handle={(props) => <CustomTooltip {...props} />}
              />
            </div>
            <div className="rangeInputs">
              <div className="inputGroup">
                <input type="number" value={filters.credit_spent[0]} />
              </div>
              <div className="inputGroup">
                <input type="number" value={filters.credit_spent[1]} />
              </div>
            </div>
          </div>
          <div className="cardDrop">
            <p className="title">Credits Added</p>
            <div className="rangeSlides">
              <Slider
                range
                min={0}
                max={100}
                // value={rangeValue}
                // onChange={handleRangeChange}
                onChange={(values) =>
                  handleRangeChange("credits_added", values)
                }
                handle={(props) => <CustomTooltip {...props} />}
              />
            </div>
            <div className="rangeInputs">
              <div className="inputGroup">
                <input type="number" value={filters.credits_added[0]} />
              </div>
              <div className="inputGroup">
                <input type="number" value={filters.credits_added[1]} />
              </div>
            </div>
          </div>
        </div>
        <div className="filterFooter">
          <button
            type="submit"
            className="btn"
            onClick={handleApplyFilters}
            disabled={validationError}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};
export default SpendingHistoryFilter;
