import React from "react";
import "bootstrap/dist/js/bootstrap.bundle.min";
import App from "./App";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";
import configureStore from "./config";
import "./index.scss";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";
import 'primeicons/primeicons.css';

let { store, persistor } = configureStore();

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
