import React from "react";
import { Link } from "react-router-dom";

const BackLink = ({ type }) => {
  return (
    <div className="backLink">
      {type === "update" ? (
        <Link to="/settings">
          <i className="fa fa-angle-left"></i> Back
        </Link>
      ) : (
        <Link to="/sign-up">
          <i className="fa fa-angle-left"></i> Back
        </Link>
      )}
    </div>
  );
};

export default BackLink;
