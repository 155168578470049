import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import slideImage from "../../../assets/images/slide-image.jpg";
import { linkdin, set_business_email } from "../../../context/actions/User";
import { emailRegistrarionValidation } from "../../../utils/Validations/Validations";
import AuthRedirectHome from "../AuthRedirectHome";
import { useState } from "react";
import { hostURl } from "../../../config/http";
const RegistrationEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const initialValues = {
    email: "",
  };

  useEffect(() => {
    // Extracting access code from URL parameters when redirected from LinkedIn
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code) {
      // If access code exists, dispatch action to send it to the backend
      dispatch(
        linkdin(code, (res) => {
          if (res.status === 200) {
            navigate("/dashboard");
          }
        })
      );
    }
  }, [window.location.search]); // Trigger useEffect when location.search changes

  const onSubmit = (values) => {
    dispatch(
      set_business_email(values, (result) => {
        console.log(result);
        if (result.status === 200) {
          navigate("/sign-up");
        } else {
          setErrorMessage(
            "User with this email already exists! Please use a different email"
          );
        }
      })
    );
  };
  const clientId = process.env.REACT_APP_CLIENT_ID
  const redirectUri = `${hostURl}/login`;

  const linkedinAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid,profile,email`;
  return (
    <>
      <AuthRedirectHome />
      <div className="registerPage authPages">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="contentSlider">
                <div className="imageBlock">
                  <img src={slideImage} className="slideImage" alt="" />
                </div>
                <div className="contentBlock text-center">
                  <h2>Welcome Back!</h2>
                  <p>
                    Elevate your business insights with precision. Log in to
                    discover and refine your Ideal Customer Profiles
                    effortlessly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="backLink">
                <Link to="/login">
                  <i className="fa fa-angle-left"></i> Back
                </Link>
              </div>

              <div className="formWrapper">
                <div className="siteLogo">
                  <img src={Logo} className="logoImage" alt="" />
                </div>
                <h2>Sign Up</h2>
                <div className="signinButton">
                  <a href={linkedinAuthUrl} className="btn btnPrimaryOutline">
                    <i className="fa fa-linkedin-square"></i> Sign In with
                    LinkedIn
                  </a>
                </div>
                <div className="optionText">
                  <p>Or</p>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={emailRegistrarionValidation}
                  onSubmit={onSubmit}
                >
                  {({ values, handleChange, handleBlur }) => (
                    <Form className="signUpForm authForm">
                      <div className="row">
                        <div className="form-group col-12">
                          <label>
                            Business Email{" "}
                            <span className="required">&#42;</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            onChange={(e) => {
                              handleChange(e);
                              setErrorMessage("");
                            }}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-message"
                          />
                          <span className="error-message">
                            {" "}
                            {errorMessage ? errorMessage : ""}
                          </span>
                        </div>
                        <div className="form-group formSubmit signinButton col-12 mt-2">
                          <button type="submit" className="btn">
                            Sign Up
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>

                <div className="accountInfo">
                  <p className="m0 text-center">
                    Already have an account? <Link to="/login">Log In</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationEmail;
