// PaymentForm.js

import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./PaymentForm.scss"; // Import your SCSS file
import { useDispatch, useSelector } from "react-redux";
import { subscribeplan } from "../../context/actions/User";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { USER_SUBSCRIPTION_DATA_SUCCESS } from "../../utils/constants";

const PaymentForm = ({ cardType, selectedSubscriptionData, billingInfo }) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const userData = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const token = useSelector((state) => state?.user?.userInfo?.token);

  useEffect(() => {
    const cardElement = elements && elements.getElement(CardElement);

    const handleChange = (event) => {
      if (event.error) {
        setError(event.error.message);
      } else {
        setError(null);
      }
    };

    if (cardElement) {
      // Add event listener only if cardElement exists
      cardElement.addEventListener("change", handleChange);
    }

    return () => {
      if (cardElement) {
        // Remove event listener only if cardElement exists
        cardElement.removeEventListener("change", handleChange);
      }
    };
  }, [elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (result.error) {
      setError(result.error.message);
    } else {
      setError(null);

      const payload = {
        user_id: userId,
        id: selectedSubscriptionData.id,
        stripe_plan_id: selectedSubscriptionData.stripe_plan_id,
        payment_method_id: result.paymentMethod.id,
      };
      dispatch(
        subscribeplan(payload, token, (result) => {
          if (result.status === 200) {
            toast("Payment Successfull", { autoClose: 1000 });
            dispatch({
              type: USER_SUBSCRIPTION_DATA_SUCCESS,
              payload: result,
            });
            navigate("/dashboard");
          } else {
            toast("Paymet Failed");
            navigate("/payments");
          }
        })
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <div className="StripeElement">
        <CardElement className="card-element" />
      </div>

      {error && <div className="error-message">{error}</div>}

      <button
        type="submit"
        className="pay-payment-btn"
        disabled={!stripe || error}
        style={{ marginTop: "5px" }}
      >
        Pay with {cardType}
      </button>
    </form>
  );
};

export default PaymentForm;
