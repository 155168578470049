import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const TICKET_TYPES = ["Open", "Resolved", "In Progress", "Overdue"];

const TicketsChart = ({ data, type }) => {
  const [chartKey, setChartKey] = useState(Date.now());
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: "bar",
        stacked: true,
        height: 450,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
          export: {
            csv: {
              headerCategory: "Date",
              valueFormatter(y) {
                return y ? y : 0
              }
  
            },
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "25%",
          endingShape: "rounded",
          dataLabels: {
            textAnchor: "middle",
            distributed: false,
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: "12px",
              },
            },
          },
        },
      },
      xaxis: {
        categories: [],
        tooltip: {
          enabled: false,
        },
        tickPlacement: "on",
      },
      yaxis: {
        min: 0,
        title: {
          text: "Total Count",
        },
        labels: {
          formatter: (val) => Math.round(val),
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return Math.round(val);
          },
        },
        enabled: true,
      },
    },
    series: [],
  });

  useEffect(() => {
    const transformData = (data) => {
      const categories = Object.keys(data).sort();

      const seriesData = TICKET_TYPES.map((type) => {
        const dataForType = categories.map((date) => data[date]?.[type] ?? 0);

        const isAllZero = dataForType.every((value) => value === 0);

        const transformedDataForType = isAllZero
          ? dataForType.map(() => null)
          : dataForType;

        return { name: type, data: transformedDataForType };
      });

      return { categories, series: seriesData };
    };

    if (data && Object.keys(data).length > 0) {
      const transformedData = transformData(data);

      const filteredCategories = transformedData.categories.filter((_, index) =>
        transformedData.series.some((series) => series.data[index] !== null)
      );

      setChartData({
        options: {
          ...chartData.options,
          chart: {
            animations: {
              enabled: true,
              easing: "easeinout",
              speed: 0,
              animateGradually: {
                enabled: true,
                delay: 100,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 0,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: filteredCategories,
            title: {
              text: "Date",
              offsetY: -10,
            },
            labels: {
              show: true,
              rotate: -50,
              rotateAlways: true,
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            // tickAmount: 5,
            min: 0,
            title: {
              text: "Total Count",
            },
            labels: {
              formatter: (val) => Math.round(val),
            },
            forceNiceScale: true,
          },
        },
        series: transformedData.series,
      });
    }
    setChartKey(Date.now());
  }, [data, type]);

  if (!data || Object.keys(data).length === 0) {
    return (
      <div className="d-flex justify-content-center" style={{padding:"200px 0px"}}>No data available</div>
    );
  }

  return (
    <div style={{ height: "400px", width: "100%" }}>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={400}
        width="100%"
      />
    </div>
  );
};

export default TicketsChart;
