import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export const handleCreateConfirmation = (
  event,
  _handleYes,
  _handleNo,
  cb = null
) => {
  return confirmAlert({
    // title: "You want to delete this file?",
    message: "Are you sure to perform this action?",
    overlayClassName: "confirmation-popup",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          _handleYes(event);
        },
      },
      {
        label: "No",
        onClick: () => _handleNo(),
      },
    ],
  });
};
