// PaymentForm.js

import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./CreditPaymentForm.scss"; // Import your SCSS file
import { useDispatch, useSelector } from "react-redux";
import { bundelePlan } from "../../context/actions/User";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CreditPaymentForm = ({
  cardType,

  totalAmount,
  credits,
  creditsError,
}) => {
  console.log(totalAmount,credits,creditsError,"<<<<<<<<");
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const userData = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const token = useSelector((state) => state?.user?.userInfo?.token);

  useEffect(() => {
    const cardElement = elements.getElement(CardElement);

    const handleChange = (event) => {
      if (event.error) {
        setError(event.error.message);
      } else {
        setError(null);
      }
    };

    // Use addEventListener directly on cardElement
    cardElement.addEventListener("change", handleChange);

    return () => {
      // Cleanup event listener on component unmount
      cardElement.removeEventListener("change", handleChange);
    };
  }, [elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (result.error) {
      setError(result.error.message);
    } else {
      setError(null);

      const payload = {
        user_id: userId,
        amount: Math.round(totalAmount),
        payment_method_id: result.paymentMethod.id,
        credits: Number(credits),
      };
      dispatch(
        bundelePlan(payload, token, (result) => {
          if (result.status === 200) {
            toast("Credits Added Successfully");
            navigate("/dashboard");
          }
        })
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <div className="StripeElement">
        <CardElement className="card-element" />
      </div>

      {error && <div className="error-message">{error}</div>}

      <button
        type="submit"
        className="pay-payment-btn"
        disabled={!stripe || creditsError || !totalAmount} // Disable if there is an error with credits
        style={{ marginTop: "5px" }}
      >
        Pay with {cardType} Amount ${totalAmount}
      </button>
    </form>
  );
};

export default CreditPaymentForm;
