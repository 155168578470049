import React, { useState, useEffect, useRef } from "react";

import "./ICPResult.scss";
import { Link } from "react-router-dom";
import "rc-slider/assets/index.css";

import { useSelector } from "react-redux";

import Select from "react-select";

const ICPFilters = ({
  filters,
  // setFilters,
  handleFilterChange,
  handleResetFilters,
  handleApplyFilters,
}) => {
  const dropdownRef = useRef(null);
  const geoLocation = useSelector((state) => state?.geoLocation?.geolocation);
  const [getAllGeolocation, setGeoLocation] = useState([]);

  const customStyles = {
    option: (base, { isSelected }) => {
      return {
        ...base,
        color: isSelected ? "#414141" : "#414141",
        backgroundColor: isSelected ? "#fafafa" : "#ffffff",
        cursor: "pointer",

        "&:hover": {
          backgroundColor: "#fafafa",
        },
        "&:focus": {
          backgroundColor: "#fafafa",
        },
      };
    },

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#ffffff",
      padding: "0",
      border: "none",
      borderBottom: "1px solid #E6E6E6",
      boxShadow: "none",
      borderRadius: "0",
      fontSize: "16px",
      "&:hover": {
        borderColor: "#E6E6E6",
        backgroundColor: "transparent",
      },
      "&:focus": {
        borderColor: "#E6E6E6",
        backgroundColor: "transparent",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "6px 0",
      maxHeight:"100px",
      overflowY:"auto"
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#414141",
      },
    }),
    Input: (base) => ({
      ...base,
      padding: "0",
      margin: "0",
    }),

    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#414141",
      margin: "0",
    }),
  };
  const all_industry = useSelector(
    (state) => state?.icpScoreResult?.icpScore?.industry_name
  );

  const [getIndustryList, setGetIndustyList] = useState([]);
  const [getCompanySize, setGetCompanySize] = useState([
    { label: "1-10", value: "1-10" },
    { label: "11-50", value: "11-50" },
    { label: "51-200", value: "51-200" },
    { label: "201-500", value: "201-500" },
    { label: "501-1,000", value: "501-1,000" },
    { label: "1,001-5,000", value: "1,001-5,000" },
    { label: "5,001-10,000", value: "5,001-10,000" },
    { label: "10,001+", value: "10,001+" },
  ]);
  const [getCompanyRevenue, setGetCompanyRevenue] = useState([
    { label: "<$1M", value: "<$1M" },
    { label: "$1M - $10M", value: "$1M - $10M" },
    { label: "$11M - $50M", value: "$11M - $50M" },
    { label: "$51M - $100M", value: "$51M - $100M" },
    { label: "$101M - $500M", value: "$101M - $500M" },
    { label: "$501M - $1B", value: "$501M - $1B" },
    { label: "$1B+", value: "$1B+" },
  ]);

  useEffect(() => {
    if (all_industry) {
      // Map to { label, value } format
      const mappedIndustries = all_industry?.map((industry, index) => ({
        label: industry?.industry_name,
        value: industry?.industry_name, // Use the index or another unique value
      }));

      // Set the sorted list
      setGetIndustyList(mappedIndustries);
    }
  }, [all_industry]);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest(".dropdown-menu")
    ) {
      // Click occurred outside the dropdown menu, close it
      // Call your close dropdown function here
    }
  };

  useEffect(() => {
    const convertedData = [
      { value: "selectAll", label: "Select All" },
      ...geoLocation?.map((item) => ({
        value: item.country,
        label: item.country,
      })),
    ];
    setGeoLocation(convertedData);
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleIndustrySelectChange = (selectedOptions) => {
    const selectedValuesArray = selectedOptions.map((option) => option.value);
    handleFilterChange("industry", selectedValuesArray);
  };

  const handleCompanySizeSelectChange = (selectedOptions) => {
    const selectedValuesArray = selectedOptions.map((option) => option.value);
    handleFilterChange("companySize", selectedValuesArray);
  };

  const handleCompanyRevenueSelectChange = (selectedOptions) => {
    const selectedValuesArray = selectedOptions.map((option) => option.value);
    handleFilterChange("companyRevenue", selectedValuesArray);
  };

  const handleGeolocationSelectChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "selectAll")) {
      if (selectedOptions.length === getAllGeolocation.length) {
        handleFilterChange("location", []);
      } else {
        handleFilterChange(
          "location",
          getAllGeolocation
            .filter((option) => option.value !== "selectAll")
            .map((option) => option.value)
        );
      }
    } else {
      handleFilterChange(
        "location",
        selectedOptions ? selectedOptions.map((option) => option.value) : []
      );
    }
  };

  return (
    <div className="dropdown filterContent" ref={dropdownRef}>
      <div
        className="filterDropdown drop"
        id="filterDropdown"
        data-bs-toggle="dropdown"
        // data-bs-auto-close="false"
        // aria-expanded="false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
            stroke="#414141"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>{" "}
        Filters
      </div>
      <div className="dropdown-menu" aria-labelledby="filterDropdown">
        <div className="dropdownHeading">
          <p>Filter</p>
          <Link to="" onClick={handleResetFilters}>
            Reset
          </Link>
        </div>
        <div className="dropdownContent" onClick={(e) => e.stopPropagation()}>
          <div className="cardDrop">
            <p className="title">Main Industry</p>
            <form>
              <div className="row">
                <Select
                  options={getIndustryList}
                  styles={customStyles}
                  className="selectBox"
                  placeholder="Select"
                  name="IndustryList"
                  value={
                    filters.industry.length > 0
                      ? filters.industry[filters.industry.length - 1].map(
                          (option) => ({
                            value: option,
                            label: option,
                          })
                        )
                      : ""
                  }
                  onChange={handleIndustrySelectChange}
                  isMulti
                />
              </div>
            </form>
          </div>
          <div className="cardDrop">
            <p className="title">Company Size</p>

            <Select
              options={getCompanySize}
              styles={customStyles}
              className="selectBox"
              placeholder="Select"
              name="IndustryList"
              value={
                filters.companySize.length > 0
                  ? filters.companySize[filters.companySize.length - 1].map(
                      (option) => ({
                        value: option,
                        label: option,
                      })
                    )
                  : ""
              }
              isMulti
              onChange={handleCompanySizeSelectChange}
            />
          </div>
          <div className="cardDrop">
            <p className="title">Company Revenue</p>

            <Select
              options={getCompanyRevenue}
              styles={customStyles}
              className="selectBox"
              placeholder="Select"
              name="IndustryList"
              value={
                filters.companyRevenue.length > 0
                  ? filters.companyRevenue[
                      filters.companyRevenue.length - 1
                    ].map((option) => ({
                      value: option,
                      label: option,
                    }))
                  : ""
              }
              isMulti
              onChange={handleCompanyRevenueSelectChange}
            />
          </div>
          <div className="cardDrop">
            <p className="title">Geolocation</p>
            <Select
              options={getAllGeolocation}
              styles={customStyles}
              className="selectBox"
              placeholder="Select"
              name="location"
              value={
                filters.location.length > 0
                  ? filters.location[
                      filters.location.length - 1
                    ].map((option) => ({
                      value: option,
                      label: option,
                    }))
                  : ""
              }
              onChange={handleGeolocationSelectChange}
              isMulti
            />
          </div>
        </div>
        <div className="filterFooter">
          <button
            id="apply-button"
            type="submit"
            className="btn"
            onClick={handleApplyFilters}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default ICPFilters;
