import React, { useState, useEffect } from "react";
import handIcon from "../../assets/images/handIcon.png";

import filterIcon from "../../assets/images/filterIcon.png";
import "./Dashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import DashboradCreditDetails from "./DashboradCreditDetails";
import {
  getUserDetails,
  getUserSubscriptionPlanDetails,
  getProfileDownloads,
  getPieProfileDownloads,
  getTicketsGraphData,
} from "../../context/actions/User";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
} from "chart.js";
import IcpGraph from "./DetailedOverviewChart";
import { ButtonGroup } from "./ButtonGroup";
import { REVENUE_MAP, COMPANY_SIZE_MAP } from "./FormLables";
import PieProfileChart from "./PieProfileGraph";
import TicketsChart from "./TicketsGraph";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,

  Filler
);

const formatLabel = (subType, key) => {
  if (subType === "revenue") {
    return REVENUE_MAP[key] || key;
  } else if (subType === "emp_size") {
    return COMPANY_SIZE_MAP[key] || key;
  }
  // Add other subType formatting if needed
  return key;
};

const Dashboard = () => {
  const dispatch = useDispatch();
  // const userName = useSelector((state) => state?.user?.userInfo?.first_name);
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const [getUserData, setGetUserData] = useState([]);

  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("30");
  const [customDateRange, setCustomDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [dateError, setDateError] = useState("");
  const [profileGraphData, setProfileGraphData] = useState();
  const [selectedType, setSelectedType] = useState("ICP");
  const [selectedSubType, setSelectedSubType] = useState("emp_size");
  const [profileDownloadsData, setProfileDownloadsData] = useState();
  const [ticketGraphData, setTicketGraphData] = useState();
  const [dashboarNoFilterData, setDashboarNoFilterData] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  //Here we are taking the data from the get usr details api
  useEffect(() => {
    if (userId) {
      const payload = {
        userId: userId,
      };
      dispatch(
        getUserDetails(payload, token, (result) => {
          setGetUserData(result);
        })
      );
    }
  }, []);

  const normalizeLabel = (label) => {
    return label.replace(/\s*-\s*/g, " - "); // Ensure spaces around hyphens
  };

  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
        type: selectedType,
        subType: selectedSubType,
        days: parseInt(selectedTimeFrame, 10),
      };

      dispatch(
        getProfileDownloads(payload, token, (result) => {
          let initialCounts = {};
          if (selectedSubType === "revenue") {
            Object.keys(REVENUE_MAP).forEach((key) => {
              initialCounts[normalizeLabel(REVENUE_MAP[key])] = 0;
            });
          } else if (selectedSubType === "emp_size") {
            Object.keys(COMPANY_SIZE_MAP).forEach((key) => {
              initialCounts[normalizeLabel(COMPANY_SIZE_MAP[key])] = 0;
            });
          }

          const aggregatedData = {};

          Object.entries(result.datewise_counts).forEach(([date, counts]) => {
            if (!aggregatedData[date]) {
              aggregatedData[date] = { ...initialCounts };
            }
            for (const [key, value] of Object.entries(counts)) {
              const normalizedLabel = normalizeLabel(
                formatLabel(selectedSubType, key)
              );
              if (aggregatedData[date][normalizedLabel] !== undefined) {
                aggregatedData[date][normalizedLabel] += value;
              } else {
                aggregatedData[date][normalizedLabel] = value;
              }
            }
          });

          const formattedData = Object.entries(aggregatedData).map(
            ([date, counts]) => ({
              date,
              counts,
            })
          );

          setProfileGraphData(formattedData);
        })
      );
    }
  }, [userId, selectedType, selectedSubType, token, dispatch]);

  //this useEffect is to show pie chart to show downloads
  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getPieProfileDownloads(payload, token, (result) => {
          setProfileDownloadsData(result);
        })
      );
    }
  }, []);

  //this useEffect is to show ticket chart to show downloads
  useEffect(() => {
    if (userId && selectedType === "Tickets") {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getTicketsGraphData(payload, token, (result) => {
          setTicketGraphData(result.data);
        })
      );
    }
  }, [selectedType]);

  // this use effect is used for getting the subscription details data and shown in dashboard right corner
  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          setGetUserSubscriptionData(result?.data);
        })
      );
    }
  }, []);

  const handleTimeFrameSelection = (timeFrame) => {
    setSelectedTimeFrame(timeFrame);
  };

  const handleCustomDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setCustomDateRange({ ...customDateRange, startDate: value });
      if (value >= customDateRange.endDate) {
        setDateError("Start date must be before end date.");
      } else {
        setDateError("");
      }
    } else if (name === "endDate") {
      setCustomDateRange({ ...customDateRange, endDate: value });
      if (value <= customDateRange.startDate) {
        setDateError("End date must be after start date.");
      } else {
        setDateError("");
      }
    }
  };

  const handleSubTypeClick = (cardType) => {
    setSelectedSubType(cardType);
  };

  const handleTypeClick = (type) => {
    setSelectedType(type);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (dateError) return; // Don't proceed if there's an error
    const payload = {
      user_id: userId,
      type: selectedType,
      subType: selectedSubType,
      days: parseInt(selectedTimeFrame),
    };
    dispatch(
      getProfileDownloads(payload, token, (result) => {
        // Initialize the formatted data with all possible choices set to 0
        let initialCounts = {};
        if (selectedSubType === "revenue") {
          Object.keys(REVENUE_MAP).forEach((key) => {
            initialCounts[REVENUE_MAP[key]] = 0;
          });
        } else if (selectedSubType === "emp_size") {
          Object.keys(COMPANY_SIZE_MAP).forEach((key) => {
            initialCounts[COMPANY_SIZE_MAP[key]] = 0;
          });
        }

        // Format label for each data point
        const formattedData = Object.entries(result.datewise_counts).map(
          ([date, counts]) => {
            const formattedCounts = { ...initialCounts };
            for (const [key, value] of Object.entries(counts)) {
              formattedCounts[formatLabel(selectedSubType, key)] = value;
            }
            return {
              date,
              counts: formattedCounts,
            };
          }
        );

        setProfileGraphData(formattedData);
      })
    );
  };
  // Here you can send the payload to your API
  const isApplyButtonDisabled =
    selectedTimeFrame === "Custom" &&
    (!customDateRange.startDate || !customDateRange.endDate || dateError);

  return (
    <>
      <div className="pageHeadingBlock">
        <div className="pageTitle">
          <h3>
            Hello, {getUserData?.company_name}!
            <img src={handIcon} alt="" />
          </h3>
        </div>
        <div
          style={{ cursor: "text" }}
          className="dropdown daysSearchFilter days-filter-block mb-3"
        >
          <span>
            {" "}
            {selectedTimeFrame === "7" && `Last 7 days`}
            {selectedTimeFrame === "30" && `Last 30 days`}
            {selectedTimeFrame === "180" && `Last 6 Months`}
            {selectedTimeFrame === "365" && `Last Year`}
          </span>
          <div
            className="searchFilter"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img style={{ cursor: "pointer" }} src={filterIcon} alt="" />
          </div>
          <div className="dropdown-menu dd-block">
            <div className="filterHeading">
              <h5>Time Frame</h5>
            </div>
            <div className="filterLisiting">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    id="timeframe-7"
                    className="formCheck"
                    type="radio"
                    checked={selectedTimeFrame === "7"}
                    onChange={() => handleTimeFrameSelection("7")}
                  />
                  <label
                    htmlFor="timeframe-7"
                    onClick={(event) => event.stopPropagation()}
                    className="formCheckLabel"
                  >
                    Last 7 days
                  </label>
                </div>
                <div className="form-group">
                  <input
                    id="timeframe-30"
                    className="formCheck"
                    type="radio"
                    checked={selectedTimeFrame === "30"}
                    onChange={() => handleTimeFrameSelection("30")}
                  />
                  <label
                    htmlFor="timeframe-30"
                    onClick={(event) => event.stopPropagation()}
                    className="formCheckLabel"
                  >
                    Last 30 days
                  </label>
                </div>
                <div className="form-group">
                  <input
                    id="timeframe-180"
                    className="formCheck"
                    type="radio"
                    checked={selectedTimeFrame === "180"}
                    onChange={() => handleTimeFrameSelection("180")}
                  />
                  <label
                    htmlFor="timeframe-180"
                    onClick={(event) => event.stopPropagation()}
                    className="formCheckLabel"
                  >
                    Last 6 Months
                  </label>
                </div>
                <div className="form-group">
                  <input
                    id="timeframe-365"
                    className="formCheck"
                    type="radio"
                    checked={selectedTimeFrame === "365"}
                    onChange={() => handleTimeFrameSelection("365")}
                  />
                  <label
                    htmlFor="timeframe-365"
                    onClick={(event) => event.stopPropagation()}
                    className="formCheckLabel"
                  >
                    Last Year
                  </label>
                </div>
                {/* Uncomment the code below if you want to include the custom date range option */}
                {/*
        <div className="form-group flex-column align-items-start m0">
          <div className="d-flex align-items-center">
            <input
              id="timeframe-custom"
              className="formCheck"
              type="radio"
              checked={selectedTimeFrame === "Custom"}
              onChange={() => handleTimeFrameSelection("Custom")}
            />
            <label htmlFor="timeframe-custom" className="formCheckLabel">
              Custom
            </label>
          </div>
          {selectedTimeFrame === "Custom" && (
            <div className="selectCustomDate">
              <div className="form-group">
                <label>From</label>
                <input
                  type="date"
                  className="form-control"
                  name="startDate"
                  value={customDateRange.startDate}
                  onChange={handleCustomDateChange}
                  placeholder="MM/DD/YYYY"
                />
              </div>

              <div className="form-group">
                <label>To</label>
                <input
                  type="date"
                  className="form-control"
                  name="endDate"
                  value={customDateRange.endDate}
                  onChange={handleCustomDateChange}
                  placeholder="MM/DD/YYYY"
                />
              </div>
              {dateError && <p className="text-danger">{dateError}</p>}
            </div>
          )}
        </div>
        */}
                <div className="filterFooter">
                  <button
                    type="submit"
                    className="btn"
                    onClick={() => {
                      setIsFilterApplied(true);
                      // Add any logic needed to handle form submission here
                      // Close the card or perform other actions
                    }}
                    disabled={isApplyButtonDisabled}
                  >
                    Apply
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12">
            <div className="contentWrapper dashboardPage">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="contentCard">
                    <div className="contentBox">
                      <div className="textBlock">
                        <p>Profiles Downloaded</p>
                        <p>
                          <h6>EMP Size Wise</h6>
                        </p>
                      </div>
                      <div className="graphBox">
                        <PieProfileChart
                          data={profileDownloadsData?.emp_size}
                          type="emp_size"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="contentCard">
                    <div className="contentBox">
                      <div className="textBlock">
                        <p>Profiles Downloaded</p>
                        <p>
                          <h6>Industry Wise</h6>
                        </p>
                      </div>
                      <div className="graphBox">
                        <PieProfileChart
                          data={profileDownloadsData?.industry}
                          type="industry"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="contentCard">
                    <div className="contentBox">
                      <div className="textBlock">
                        <p>Profiles Downloaded</p>
                        <p>
                          <h6>Company Revenue Wise</h6>
                        </p>
                        <h3>
                          {
                            dashboarNoFilterData?.prospect_data
                              ?.total_prospect_download?.total_no_of_companies
                          }
                        </h3>
                      </div>
                      <div className="graphBox">
                        <PieProfileChart
                          data={profileDownloadsData?.revenue}
                          type="revenue"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="detailsOverviewGraph">
                    <div className="headingBlock">
                      <h5>
                        <strong>Detailed Overview Chart</strong>
                      </h5>
                      <p className="selectedDays">
                        <strong>
                          {" "}
                          {selectedTimeFrame === "7" && `Last 7 days`}
                          {selectedTimeFrame === "30" && `Last 30 days`}
                          {selectedTimeFrame === "180" && `Last 6 Months`}
                          {selectedTimeFrame === "365" && `Last Year`}
                        </strong>
                      </p>
                    </div>
                    <div className="contentBlock">
                      <ul className="nav nav-tabs" id="nav-tab" role="tablist">
                        <li
                          className="nav-link active"
                          id="ICPDownlodedTab"
                          data-bs-toggle="tab"
                          data-bs-target="#ICPDownloded"
                          type="button"
                          role="tab"
                          aria-controls="ICPDownloded"
                          aria-selected="true"
                          onClick={() => handleTypeClick("ICP")}
                        >
                          VAIS Downloded
                        </li>
                        <li
                          className="nav-link"
                          id="ProspectsDownloadedTab"
                          data-bs-toggle="tab"
                          data-bs-target="#ProspectsDownloaded"
                          type="button"
                          role="tab"
                          aria-controls="ProspectsDownloaded"
                          aria-selected="false"
                          onClick={() => handleTypeClick("Prospect")}
                        >
                          Prospects Downloaded
                        </li>
                        <li
                          className="nav-link"
                          id="AccountsVerifiedTab"
                          data-bs-toggle="tab"
                          data-bs-target="#AccountsVerified"
                          type="button"
                          role="tab"
                          aria-controls="AccountsVerified"
                          aria-selected="false"
                          onClick={() => handleTypeClick("ABM")}
                        >
                          Accounts Verified
                        </li>
                        <li
                          className="nav-link"
                          id="LALsGeneratedTab"
                          data-bs-toggle="tab"
                          data-bs-target="#LALsGenerated"
                          type="button"
                          role="tab"
                          aria-controls="LALsGenerated"
                          aria-selected="false"
                          onClick={() => handleTypeClick("LAL")}
                        >
                          LALs Generated
                        </li>
                        <li
                          className="nav-link"
                          id="TicketsTab"
                          data-bs-toggle="tab"
                          data-bs-target="#Tickets"
                          type="button"
                          role="tab"
                          aria-controls="Tickets"
                          aria-selected="false"
                          onClick={() => handleTypeClick("Tickets")}
                        >
                          Tickets
                        </li>
                      </ul>
                      <div className="tab-content" id="nav-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="ICPDownloded"
                          role="tabpanel"
                          aria-labelledby="ICPDownlodedTab"
                          style={{
                            width: "100%" /* Adjust as needed */,
                            height: "400px",
                          }}
                        >
                          <div className="d-flex table-block">
                            <div className="d-flex flex-column justify-content-center">
                              <ButtonGroup
                                handleSubTypeClick={handleSubTypeClick}
                                selectedSubType={selectedSubType}
                              />
                            </div>
                            <div className="flex-grow-1 graph_block">
                              {profileGraphData && (
                                <IcpGraph
                                  profileGraphData={profileGraphData}
                                  type={selectedSubType}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="ProspectsDownloaded"
                          role="tabpanel"
                          aria-labelledby="ProspectsDownloadedTab"
                          style={{
                            width: "100%" /* Adjust as needed */,
                            height: "400px",
                          }}
                        >
                          <div className="d-flex table-block">
                            <div className="d-flex flex-wrap flex-column justify-content-center">
                              <ButtonGroup
                                handleSubTypeClick={handleSubTypeClick}
                                selectedSubType={selectedSubType}
                              />
                            </div>
                            <div className="flex-grow-1 graph_block">
                              <IcpGraph
                                profileGraphData={profileGraphData}
                                type={selectedSubType}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="AccountsVerified"
                          role="tabpanel"
                          aria-labelledby="AccountsVerifiedTab"
                          style={{
                            width: "100%" /* Adjust as needed */,
                            height: "400px",
                          }}
                        >
                          <div className="d-flex table-block">
                            <div className="d-flex flex-column justify-content-center">
                              <ButtonGroup
                                handleSubTypeClick={handleSubTypeClick}
                                selectedSubType={selectedSubType}
                              />
                            </div>
                            <div className="flex-grow-1 graph_block">
                              <IcpGraph
                                profileGraphData={profileGraphData}
                                type={selectedSubType}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="LALsGenerated"
                          role="tabpanel"
                          aria-labelledby="LALsGeneratedTab"
                          style={{
                            width: "100%" /* Adjust as needed */,
                            height: "400px",
                          }}
                        >
                          <div className="d-flex table-block">
                            <div className="d-flex flex-column justify-content-center">
                              <ButtonGroup
                                handleSubTypeClick={handleSubTypeClick}
                                selectedSubType={selectedSubType}
                              />
                            </div>
                            <div className="flex-grow-1 graph_block">
                              <IcpGraph
                                profileGraphData={profileGraphData}
                                type={selectedSubType}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="Tickets"
                          role="tabpanel"
                          aria-labelledby="TicketsTab"
                          style={{
                            width: "100%" /* Adjust as needed */,
                            height: "400px",
                          }}
                        >
                          <TicketsChart
                            data={ticketGraphData}
                            type={selectedType}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pageContentBlock">
          <div className="row mt-4">
            <div className="col-12 col-sm-3 mb-5">
              <div className="contentWrapper sideContentWrapper dashboardPage">
                <div className="contentCard">
                  <div className="contentBox">
                    <div className="textBlock text-center">
                      <p>
                        <strong>Accounts Verified : </strong>
                        {"  "}
                        <span>
                          {profileDownloadsData?.abm_downloaded
                            ? profileDownloadsData?.abm_downloaded
                            : 0}
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* <div className="recordContent">
                    <h4>
                      {profileDownloadsData?.abm_percentage}{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="16"
                        viewBox="0 0 12 16"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.59113 15.7637H4.18565V8.54422H0L5.88843 0.237096L11.7769 8.54422H7.59113V15.7637Z"
                          fill="#3AB37E"
                        />
                      </svg>
                    </h4>
                    <p>During last 30 days</p>
                  </div> */}
                </div>
                <div className="contentCard">
                  <div className="contentBox">
                    <div className="textBlock text-center">
                      <p>
                        <strong>LALs Generated : </strong>{" "}
                        <span>
                          {profileDownloadsData?.lal_downloaded
                            ? profileDownloadsData?.lal_downloaded
                            : 0}
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                  {/* <div className="recordContent">
                    <h4>
                      {profileDownloadsData?.lal_percentage}{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="16"
                        viewBox="0 0 12 16"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.59113 15.7637H4.18565V8.54422H0L5.88843 0.237096L11.7769 8.54422H7.59113V15.7637Z"
                          fill="#3AB37E"
                        />
                      </svg>
                    </h4>
                    <p>During last 30 days</p>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-9">
              <div className="contentWrapper">
                <DashboradCreditDetails
                  getUserSubscriptionData={getUserSubscriptionData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
