import { ErrorMessage, Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { change_password, handleLogout } from "../../../context/actions/User";
import { changePasswordValidation } from "../../../utils/Validations/Validations";
import "./index.scss";
import Success from "../../Profile/Modals/Success/Success";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const token = useSelector((state) => state?.user?.userInfo?.token);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");

  const userData = useSelector((state) => state?.user.userInfo);

  const onSubmit = (values) => {
          // Check if old password and new password are the same
  if (values.old_password === values.new_password) {
    // Display a toast message indicating that old and new passwords cannot be the same
    toast("Old password and new password cannot be the same", { autoClose: 1000 });
    return; // Exit the function early
  }
    const payload = {
      old_password: values.old_password,
      new_password: values.new_password,
      email: userData?.email,

    };
    dispatch(
      change_password(payload, token,(res) => {
        if (res.status === 200) {
          setModal(true);
          setMessage(res.message);
        } else if (res.status === 400) {
          toast(res.message,{autoClose:1000})
        }
      })
    );
  };

  const onModalClose = () => {
    setModal(false);
    dispatch(handleLogout());
    navigate("/login");
  };

  return (
    <>
      <div className="pageContentBlock change-password-page">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper profileSettingsPage">
              <div className="row">
                <div className="col-12">
                  <div className="userPersonalDetails">
                    <div className="col-12 col-md-12">
                      <div className="formWrapper">
                        <h2>Set New Password</h2>
                        <p className="smText text-center">
                          Your new password must be different to previously used
                          passwords.
                        </p>

                        <Formik
                          initialValues={initialValues}
                          validationSchema={changePasswordValidation}
                          onSubmit={onSubmit}
                        >
                          {({ values, handleChange, handleBlur }) => (
                            <Form className="ChangePasswordForm">
                              <div className="row">
                                <div className="form-group">
                                  <label>
                                    Old Password{" "}
                                    <span className="required">&#42;</span>
                                  </label>
                                  <div className="passwordField">
                                    <input
                                      type={
                                        oldPasswordVisible ? "text" : "password"
                                      }
                                      className="form-control"
                                      placeholder="Old Password"
                                      name="old_password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                                    />
                                    <i
                                      className={`fa ${
                                        oldPasswordVisible
                                          ? "fa-eye"
                                          : "fa-eye-slash"
                                      }`}
                                      onClick={() =>
                                        setOldPasswordVisible(
                                          !oldPasswordVisible
                                        )
                                      }
                                    ></i>
                                  </div>
                                  <ErrorMessage
                                    name="old_password"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                                <div className="form-group">
                                  <label>
                                    New Password{" "}
                                    <span className="required">&#42;</span>
                                  </label>
                                  <div className="passwordField">
                                    <input
                                      type={
                                        passwordVisible ? "text" : "password"
                                      }
                                      className="form-control"
                                      placeholder="New Password"
                                      name="new_password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                                    />

                                    <i
                                      className={`fa ${
                                        passwordVisible
                                          ? "fa-eye"
                                          : "fa-eye-slash"
                                      }`}
                                      onClick={() =>
                                        setPasswordVisible(!passwordVisible)
                                      }
                                    ></i>
                                  </div>
                                  <ErrorMessage
                                    name="new_password"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                                <div className="form-group">
                                  <label>
                                    Confirm Password{" "}
                                    <span className="required">&#42;</span>
                                  </label>
                                  <div className="passwordField">
                                    <input
                                      type={
                                        confirmPasswordVisible
                                          ? "text"
                                          : "password"
                                      }
                                      className="form-control"
                                      placeholder="Confirm Password"
                                      name="confirm_password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.re_password}
                                    />
                                    <i
                                      className={`fa ${
                                        confirmPasswordVisible
                                          ? "fa-eye"
                                          : "fa-eye-slash"
                                      }`}
                                      onClick={() =>
                                        setConfirmPasswordVisible(
                                          !confirmPasswordVisible
                                        )
                                      }
                                    ></i>
                                  </div>
                                  <ErrorMessage
                                    name="confirm_password"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>

                                <div className="form-group formSubmit signinButton col-12">
                                  <button type="submit" className="btn btnPrimaryOutline">
                                    Change Password
                                  </button>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <Success
          showModal={modal}
          onClose={onModalClose}
          message={message}
          btnName="Login"
        />
      )}
    </>
  );
};

export default ChangePassword;
