import React, { useEffect, useState } from "react";
import "rc-slider/assets/index.css";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Link } from "react-router-dom";
import "./SpendingHistory.scss";
import SpendingHistoryTable from "./SpendingHistoryTable";
import SpendingHistoryFilter from "./SpendingHistoryFilter";
import { spendingHistory } from "../../context/actions/User";
import { useDispatch, useSelector } from "react-redux";
import { ITEMS_PER_PAGE } from "../../utils/constants";
import moment from "moment";

const SpendingHistory = () => {
  const handle = useFullScreenHandle();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);

  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  //to store the data from get api
  const [getListData, setGetListData] = useState([]);
  const [abc, setAbc] = useState([]);

  // const [dataToRender, setDataToRender] = useState([]);
  const itemPerPage = ITEMS_PER_PAGE;

  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [validationError, setValidationError] = useState("");

  // const [selectedRows, setSelectedRows] = useState([]);

  // for sorting
  const [sortBy, setSortBy] = useState({
    column: "",
    desc: false,
  });
  //for filters
  const [filters, setFilters] = useState({
    task: [],
    credit_spent: [0, 100],
    credits_added: [0, 100],
    date: {
      startDate: "",
      endDate: "",
    },
  });

  //this dataToRender is for updating the data when the page is changing thats why taken in useState
  const [dataToRender, setDataToRender] = useState(
    getListData?.slice(0, itemPerPage)
  );

  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        spendingHistory(payload, token, (result) => {
          setGetListData(result);
          setAbc(result);
          const total = Math.ceil(Math.max(result?.length, 0) / itemPerPage);
          setTotalPages(total);

          const startIndex = (currentPage - 1) * itemPerPage;
          const endIndex = startIndex + itemPerPage;
          const paginatedData = result?.slice(startIndex, endIndex);
          setDataToRender(paginatedData);
        })
      );
    }
  }, [dispatch, userId]);

  const [totalPages, setTotalPages] = useState(
    Math.ceil(Math.max(abc?.length, 0) / itemPerPage)
  );
  // const [totalPages, setTotalPages] = useState(1);
  // this useEffect is for getting the data without filter

  //handaling pagination

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      filterData(searchInput, pageNumber);
      // setSelectedRowId(null); // Reset selected row when changing pages
    }
  };

  //for serch input

  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    handlePageChange(1);
    filterData(e.target.value, 1);
  };

  const filterData = (input, pageNumber) => {
    const lowercasedInput = input.toLowerCase();

    const filtered = getListData.filter((data) => {
      const task = data.task.toLowerCase();

      // Check if the input includes "icp" and if so, exclude tasks containing "vais"
      if (lowercasedInput.includes("ic") && task.includes("icp")) {
        return false;
      }

      // Check if task includes the input or if the special condition is met
      const isSpecialCondition =
        lowercasedInput.startsWith("v") && task.includes("icp");
      const creditBalancedIncludesSearchTerm = data.credit_balanced
        ?.toString()
        .includes(lowercasedInput);
      const creditSpentIncludesSearchTerm = data.credit_spent
        ?.toString()
        .includes(lowercasedInput);
      const creditAddedIncludesSearchTerm = data.credit_added
        ?.toString()
        .includes(lowercasedInput);

      return (
        task.includes(lowercasedInput) ||
        isSpecialCondition ||
        creditBalancedIncludesSearchTerm ||
        creditSpentIncludesSearchTerm || 
        creditAddedIncludesSearchTerm
      );
    });

    const startIndex = (pageNumber - 1) * itemPerPage;
    const endIndex = startIndex + itemPerPage;
    let paginatedData = filtered.slice(startIndex, endIndex);

    setCurrentPage(pageNumber);
    setDataToRender(paginatedData);
  };

  // this is for filtering function
  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => {
      if (filterType === "task") {
        return {
          ...prevFilters,
          [filterType]: Array.isArray(prevFilters[filterType])
            ? prevFilters[filterType].includes(value)
              ? prevFilters[filterType].filter((item) => item !== value)
              : [...prevFilters[filterType], value]
            : value,
          date: {
            startDate: prevFilters.date.startDate,
            endDate: prevFilters.date.endDate,
          },
        };
      } else if (filterType === "startDate" || filterType === "endDate") {
        return {
          ...prevFilters,
          task: [...prevFilters.task],
          date: {
            ...prevFilters.date,
            [filterType]: value,
          },
        };
      } else {
        return prevFilters;
      }
    });
  };

  const handleResetFilters = () => {
    setFilters({
      task: [],
      credit_spent: [0, 100],
      credits_added: [0, 100],
      date: {
        startDate: "",
        endDate: "",
      },
    });

    setSearchInput("");
    // setSelectedRows([]);
    // setAbc(getListData);

    setGetListData(abc);

    // Calculate the new total pages based on the original data
    const newTotalPages = Math.ceil(Math.max(abc.length, 0) / itemPerPage);

    // Ensure current page is within the new total pages
    const updatedCurrentPage = Math.min(currentPage, newTotalPages);

    // Calculate the paginated data based on the updated current page and item per page
    const startIndex = updatedCurrentPage
      ? (updatedCurrentPage - 1) * itemPerPage
      : 0;
    const endIndex = startIndex + itemPerPage;
    const paginatedData = abc.slice(startIndex, endIndex);

    // Update states accordingly
    setTotalPages(newTotalPages);
    setCurrentPage(updatedCurrentPage);
    setDataToRender(paginatedData);
  };

  const handleApplyFilters = () => {
    let filtered = abc;
    // Filter by Task

    if (filters.task.length > 0) {
      filtered = filtered.filter((data) => filters.task.includes(data.task));
    }

    // Filter by credit_spent range
    if (filters.credit_spent[0] && filters.credit_spent[1]) {
      filtered = filtered.filter(
        (data) =>
          data.credit_spent >= filters.credit_spent[0] &&
          data.credit_spent <= filters.credit_spent[1]
      );
    }

    // Filter by credit_added range
    if (filters.credits_added[0] && filters.credits_added[1]) {
      filtered = filtered.filter(
        (data) =>
          data.credit_added >= filters.credits_added[0] &&
          data.credit_added <= filters.credits_added[1]
      );
    }

    if (filters.date.startDate && filters.date.endDate) {
      filtered = filtered.filter((data) => {
        const fileDate = moment(data.date).format("MM:DD:YYYY");
        // const originalDate = new Date(fileDate);
        // const day = originalDate.getUTCDate().toString().padStart(2, "0"); // Get day and pad with leading zero if necessary
        // const month = (originalDate.getUTCMonth() + 1)
        //   .toString()
        //   .padStart(2, "0"); // Get month and pad with leading zero if necessary
        // const year = originalDate.getUTCFullYear(); // Get year

        // const convertedDate = `${month}:${day}:${year}`;

        const startDate = moment(filters.date.startDate).format("MM:DD:YYYY");
        const endDate = moment(filters.date.endDate).format("MM:DD:YYYY");

        // Compare the date parts only (day, month, year)
        return fileDate >= startDate && fileDate <= endDate;
      });
    }

    // Update total pages based on the filtered data
    const newTotalPages = Math.ceil(filtered.length / itemPerPage);

    // Ensure current page is within the new total pages
    const updatedCurrentPage = Math.min(currentPage, newTotalPages);

    const startIndex = updatedCurrentPage
      ? (updatedCurrentPage - 1) * itemPerPage
      : 1;
    const endIndex = startIndex + itemPerPage;
    const paginatedData = filtered.slice(startIndex, endIndex);
    const page = updatedCurrentPage === 0 ? 1 : updatedCurrentPage;

    setGetListData(filtered);
    setCurrentPage(page);
    setTotalPages(newTotalPages);
    setDataToRender(paginatedData);
  };

  const handleRangeChange = (type, values) => {
    setFilters({
      ...filters,
      [type]: values,
    });
  };
  //sorting function
  const handleSort = (column) => {
    setSortBy((prevSortBy) => ({
      column,
      desc: column === prevSortBy.column ? !prevSortBy.desc : false,
    }));
  };

  const sortedData = [...dataToRender]?.sort((a, b) => {
    const columnA = a[sortBy.column];
    const columnB = b[sortBy.column];

    if (columnA < columnB) return sortBy.desc ? 1 : -1;
    if (columnA > columnB) return sortBy.desc ? -1 : 1;
    return 0;
  });

  return (
    <>
      <div className="pageHeadingBlock buildYourICPHeading mt-4">
        <div className="pageTitle">
          <h3>
            <Link to="/dashboard" className="backToPage">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M9.13073 15.1663L14.8474 20.8829C15.0807 21.1163 15.1925 21.3885 15.1828 21.6996C15.1731 22.0107 15.0516 22.2829 14.8182 22.5163C14.5849 22.7302 14.3127 22.842 14.0016 22.8517C13.6905 22.8614 13.4182 22.7496 13.1849 22.5163L5.4849 14.8163C5.36823 14.6996 5.28559 14.5732 5.23698 14.4371C5.18837 14.301 5.16406 14.1552 5.16406 13.9996C5.16406 13.8441 5.18837 13.6982 5.23698 13.5621C5.28559 13.426 5.36823 13.2996 5.4849 13.1829L13.1849 5.48294C13.3988 5.26905 13.6661 5.16211 13.987 5.16211C14.3078 5.16211 14.5849 5.26905 14.8182 5.48294C15.0516 5.71628 15.1682 5.99336 15.1682 6.31419C15.1682 6.63503 15.0516 6.91211 14.8182 7.14544L9.13073 12.8329H22.1682C22.4988 12.8329 22.7759 12.9447 22.9995 13.1684C23.2231 13.392 23.3349 13.6691 23.3349 13.9996C23.3349 14.3302 23.2231 14.6072 22.9995 14.8309C22.7759 15.0545 22.4988 15.1663 22.1682 15.1663H9.13073Z"
                  fill="#414141"
                />
              </svg>
            </Link>{" "}
            Spending History
          </h3>
        </div>
      </div>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper ICPResults">
              <div className="tableHeadingBlock">
                <div className="leftContent">
                  <form className="searchForm">
                    <div className="form-group m-0">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                      />
                      <button type="submit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="25"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M14.0625 14.0625L17.8125 17.8125"
                            stroke="#414141"
                            strokeOpacity="0.8"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.375 15.3125C12.6542 15.3125 15.3125 12.6542 15.3125 9.375C15.3125 6.09581 12.6542 3.4375 9.375 3.4375C6.09581 3.4375 3.4375 6.09581 3.4375 9.375C3.4375 12.6542 6.09581 15.3125 9.375 15.3125Z"
                            stroke="#414141"
                            strokeOpacity="0.8"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </form>
                  <div className="filtersBlock">
                    <SpendingHistoryFilter
                      filters={filters}
                      handleFilterChange={handleFilterChange}
                      handleResetFilters={handleResetFilters}
                      handleApplyFilters={handleApplyFilters}
                      handleRangeChange={handleRangeChange}
                      validationError={validationError}
                      setValidationError={setValidationError}
                    />
                    <div className="tableFullScreen">
                      <div
                        className="fullScreenShow d-flex align-items-center"
                        onClick={handle.enter}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M3.33301 7.4987V4.9987C3.33301 4.55667 3.5086 4.13275 3.82116 3.82019C4.13372 3.50763 4.55765 3.33203 4.99967 3.33203H7.49967M16.6663 12.4987V14.9987C16.6663 15.4407 16.4907 15.8646 16.1782 16.1772C15.8656 16.4898 15.4417 16.6654 14.9997 16.6654H12.4997M12.4997 3.33203H14.9997C15.4417 3.33203 15.8656 3.50763 16.1782 3.82019C16.4907 4.13275 16.6663 4.55667 16.6663 4.9987V7.4987M7.49967 16.6654H4.99967C4.55765 16.6654 4.13372 16.4898 3.82116 16.1772C3.5086 15.8646 3.33301 15.4407 3.33301 14.9987V12.4987"
                            stroke="#414141"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>{" "}
                        Full Screen
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rightContent">
                  <div className="paginationBlock">
                    <div className="d-flex align-items-center">
                      Page :
                      <div className="dropdown paginationDropdown">
                        <div
                          className="pageDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {/* <input
                            type="text"
                            className="pageNumber"
                            placeholder={currentPage}
                          /> */}
                          <button className="page-drop">{currentPage}</button>
                        </div>
                        <div className="dropdown-menu">
                          <ul>
                            {pageNumbers.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber.toString().padStart(2, "0")}{" "}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      of
                      <span className="totalPages"> {totalPages} </span>
                    </div>
                    <div className="pagination">
                      <div className="prev paginationItem">
                        <Link to="" className="paginationLink">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            <path
                              d="M4.75 9.25C4.65146 9.25046 4.55382 9.23124 4.46281 9.19347C4.37179 9.15569 4.28924 9.10011 4.22 9.03L0.72 5.53C0.57955 5.38937 0.50066 5.19875 0.50066 5C0.50066 4.80125 0.57955 4.61063 0.72 4.47L4.22 1C4.36096 0.908609 4.52852 0.867188 4.69582 0.882376C4.86312 0.897563 5.02048 0.96848 5.14268 1.08376C5.26488 1.19904 5.34483 1.352 5.36973 1.51814C5.39463 1.68428 5.36303 1.85396 5.28 2L2.28 5L5.28 8C5.42045 8.14063 5.49934 8.33125 5.49934 8.53C5.49934 8.72875 5.42045 8.91937 5.28 9.06C5.13527 9.19069 4.9448 9.25897 4.75 9.25Z"
                              fill="#414141"
                            />
                          </svg>
                        </Link>
                      </div>
                      <div className="next paginationItem">
                        <Link to="" className="paginationLink">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                            onClick={() => handlePageChange(currentPage + 1)}
                          >
                            <path
                              d="M1.25 9.25C1.14929 9.24661 1.05034 9.22269 0.959199 9.1797C0.868063 9.13671 0.786667 9.07556 0.72 9C0.57955 8.85937 0.50066 8.66875 0.50066 8.47C0.50066 8.27125 0.57955 8.08062 0.72 7.94L3.72 4.94L0.72 1.94C0.660676 1.79599 0.647659 1.63708 0.682755 1.48533C0.717851 1.33359 0.799319 1.19653 0.915851 1.09319C1.03238 0.989854 1.1782 0.925358 1.33305 0.908658C1.4879 0.891957 1.64411 0.923882 1.78 1L5.28 4.5C5.42045 4.64063 5.49934 4.83125 5.49934 5.03C5.49934 5.22875 5.42045 5.41938 5.28 5.56L1.78 9C1.71333 9.07556 1.63194 9.13671 1.5408 9.1797C1.44966 9.22269 1.35071 9.24661 1.25 9.25Z"
                              fill="#414141"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FullScreen handle={handle}>
                <div className="tableContentBlock table-responsive">
                  <SpendingHistoryTable
                    getListData={getListData}
                    handleSort={handleSort}
                    sortedData={sortedData}
                    sortBy={sortBy}
                  />
                </div>
              </FullScreen>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpendingHistory;
