// Function to format label based on subtype and key
export const formatLabel = (subtype, key) => {
    if (subtype === 'revenue') {
      switch (key) {
        case '1':
          return '<$1M';
        case '2':
          return '$1M - $10M';
        case '3':
          return '$11M - $50M';
        case '4':
          return '$51M - $100M';
        case '5':
          return '$101M - $500M';
        case '6':
          return '$501M - $1B';
        case '7':
          return '$1B+';
        default:
          return key;
      }
    } else if (subtype === 'emp_size') {
      switch (key) {
        case '1':
          return '1-10';
        case '2':
          return '11-50';
        case '3':
          return '51-200';
        case '4':
          return '201-500';
        case '5':
          return '501-1,000';
        case '6':
          return '1,001-5,000';
        case '7':
          return '5,001-10,000';
        case '8':
          return '10,001+';
        default:
          return key;
      }
    }
    // Handle other subtypes if needed
    return key;
  };
  
export  const REVENUE_MAP = {
    '1': '<$1M',
    '2': '$1M - $10M',
    '3': '$11M - $50M',
    '4': '$51M - $100M',
    '5': '$101M - $500M',
    '6': '$501M - $1B',
    '7': '$1B+',
  };
  
  export  const COMPANY_SIZE_MAP = {
    '1': '1-10',
    '2': '11-50',
    '3': '51-200',
    '4': '201-500',
    '5': '501-1,000',
    '6': '1,001-5,000',
    '7': '5,001-10,000',
    '8': '10,001+',
  };