import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import slideImage from "../../../assets/images/slide-image.jpg";
import { password_reset_otp_sender } from "../../../context/actions/User";
import { forgetPasswordValidation } from "../../../utils/Validations/Validations";
import AuthRedirectHome from "../AuthRedirectHome";
import "./forgotPassword.scss";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const time = 180;
  const initialValues = {
    email: "",
  };
  const onSubmit = (values) => {
    dispatch(
      password_reset_otp_sender(values, (res) => {
        if (res.status === 200) {
          navigate("/forgot-password-email-verfication");
          const timerEndTime = new Date().getTime() + time * 1000;
          localStorage.setItem("otpTimerEndTime", timerEndTime);
        }
      })
    );
  };
  return (
    <>
      <AuthRedirectHome />
      <div className="forgotPasswordPage authPages">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="contentSlider">
                <div className="imageBlock">
                  <img src={slideImage} className="slideImage" alt="" />
                </div>
                <div className="contentBlock text-center">
                  <h2>Welcome Back!</h2>
                  <p>
                    Elevate your business insights with precision. Log in to
                    discover and refine your Ideal Customer Profiles
                    effortlessly.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="col-12 col-md-6">
              <div className="backLink">
                <Link to="/login">
                  <i className="fa fa-angle-left"></i> Back
                </Link>
              </div>

              <div className="formWrapper">
              <div className="siteLogo">
                <img src={Logo} className="logoImage" alt="" />
              </div>
                <h3>Forget Password</h3>
                <p className="smText">
                  Please enter the email address associated with your account.
                </p>

                <Formik
                  initialValues={initialValues}
                  validationSchema={forgetPasswordValidation}
                  onSubmit={onSubmit}
                >
                  {({ values, handleChange, handleBlur }) => (
                    <Form className="forgotPasswordForm authForm">
                      <div className="row">
                        <div className="form-group col-12">
                          <label>
                            Business Email{" "}
                            <span className="required">&#42;</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            name="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group formSubmit signinButton col-12 mt-2">
                          <button type="submit" className="btn">
                            Send Reset Code
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>

                <div className="accountInfo">
                  <p className="m0 text-center">
                    Need Help <Link to="https://valasys.io/valasysai/troubleshoot#login-issue" target="_blank">Click Here</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
