/* eslint-disable */
import React from "react";
import { useSelector } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "./components/Loading/Loading";
import privateRoutes from "./routes/privateRoutes";
import publicRoutes from "./routes/publicRoutes";

const App = () => {
  const user = useSelector((state) => state?.user);
  const router = createBrowserRouter([
    user?.isLoggedIn ? privateRoutes() : {},
    ...publicRoutes(),
  ]);

  return (
    <>
      <Loader />
      <RouterProvider router={router} />
      <ToastContainer autoClose={5000} limit={1} />
    </>
  );
};

export default App;
