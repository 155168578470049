import React from "react";
import { Button, Modal } from "react-bootstrap";
import successIcon from "../../../../assets/images/successIcon.png";
import "./Success.scss";

const Success = ({ message, btnName, onClose, showModal }) => {
  return (
    <Modal className="successModal" show={showModal} onHide={onClose} centered>
      <Modal.Body className="modal-body text-center">
        <img src={successIcon} className="successIcon" alt="" />
        <p>{message}</p>
        <Button className="btn" onClick={onClose}>
          {btnName}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default Success;
