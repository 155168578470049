import "rc-slider/assets/index.css";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./FindProspectResult.scss";
import { useDispatch, useSelector } from "react-redux";
import { getPeospectDetails } from "../../../context/actions/User";
import Select from "react-select";

const ProspectFilter = ({
  // setFilters,
  filters,
  handleFilterChange,
  handleResetFilters,
  handleApplyFilters,
  getComName,
  getAllGeolocation
}) => {
  const dropdownRef = useRef(null);

  const customStyles = {
    option: (base, { isSelected }) => {
      return {
        ...base,
        color: isSelected ? "#414141" : "#414141",
        backgroundColor: isSelected ? "#fafafa" : "#ffffff",
        cursor: "pointer",

        "&:hover": {
          backgroundColor: "#fafafa",
        },
        "&:focus": {
          backgroundColor: "#fafafa",
        },
      };
    },

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#ffffff",
      padding: "0",
      border: "none",
      borderBottom: "1px solid #E6E6E6",
      boxShadow: "none",
      borderRadius: "0",
      fontSize: "16px",
      "&:hover": {
        borderColor: "#E6E6E6",
        backgroundColor: "transparent",
      },
      "&:focus": {
        borderColor: "#E6E6E6",
        backgroundColor: "transparent",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "6px 0",
      maxHeight:"100px",
      overflowY:"auto"
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#414141",
      },
    }),
    Input: (base) => ({
      ...base,
      padding: "0",
      margin: "0",
    }),

    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#414141",
      margin: "0",
    }),
  };
  // to get companies data we use use selector
  const abc = useSelector((state) => state?.prospectDetails?.prospectDetails);
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const dispatch = useDispatch();
  // for revenue
  const [getRevenue, setGetRevenue] = useState([]);
  //for job function
  const [getJobFunction, setGetJobFunction] = useState([]);
  //for job level
  const [getJobLevel, setGetJobLevel] = useState([]);
  //for company name

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest(".dropdown-menu")
    ) {
      // Click occurred outside the dropdown menu, close it
      // Call your close dropdown function here
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  // for data of proppect details
  useEffect(() => {
    dispatch(
      getPeospectDetails(token, (result) => {
        // Adding "Select All" option for revenue
        const convertedRevenue = [
          { value: "selectAll", label: "Select All" },
          ...result.data.revenue.map((item) => ({
            value: item.revenue_range,
            label: item.revenue_range,
          })),
        ];

        // Adding "Select All" option for job function
        const convertedJobFunction = [
          { value: "selectAll", label: "Select All" },
          ...result.job_function.map((item) => ({
            value: item.job_function,
            label: item.job_function,
          })),
        ];

        // Adding "Select All" option for job level
        const convertedJobLevel = [
          { value: "selectAll", label: "Select All" },
          ...result.job_level.map((item) => ({
            value: item.job_level,
            label: item.job_level,
          })),
        ];

        setGetRevenue(convertedRevenue);
        setGetJobFunction(convertedJobFunction);
        setGetJobLevel(convertedJobLevel);
      })
    );
  }, []);

  //handle revenue selection
  const handleCompanyRevenueSelectChange = (selectedOptions) => {
    const selectedValuesArray = selectedOptions.map((option) => option.value);
    handleFilterChange("companyRevenue", selectedValuesArray);
    if (selectedOptions.some((option) => option.value === "selectAll")) {
      if (selectedOptions.length === getRevenue.length) {
        handleFilterChange("companyRevenue", []);
      } else {
        handleFilterChange(
          "companyRevenue",
          getRevenue
            .filter((option) => option.value !== "selectAll")
            .map((option) => option.value)
        );
      }
    } else {
      handleFilterChange(
        "companyRevenue",
        selectedOptions ? selectedOptions.map((option) => option.value) : []
      );
    }
  };

  //handle job level selection
  const handleJobLevelSelectChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "selectAll")) {
      if (selectedOptions.length === getJobLevel.length) {
        handleFilterChange("jobLevel", []);
      } else {
        handleFilterChange(
          "jobLevel",
          getJobLevel
            .filter((option) => option.value !== "selectAll")
            .map((option) => option.value)
        );
      }
    } else {
      handleFilterChange(
        "jobLevel",
        selectedOptions ? selectedOptions.map((option) => option.value) : []
      );
    }
  };
  //handle job function selection

  const handleJobFunctionSelectChange = (selectedOptions) => {
    const selectedValuesArray = selectedOptions.map((option) => option.value);
    handleFilterChange("jobFunction", selectedValuesArray);
    if (selectedOptions.some((option) => option.value === "selectAll")) {
      if (selectedOptions.length === getJobFunction.length) {
        handleFilterChange("jobFunction", []);
      } else {
        handleFilterChange(
          "jobFunction",
          getJobFunction
            .filter((option) => option.value !== "selectAll")
            .map((option) => option.value)
        );
      }
    } else {
      handleFilterChange(
        "jobFunction",
        selectedOptions ? selectedOptions.map((option) => option.value) : []
      );
    }
  };


  //handle company name selection

  const handleCompanyNameSelectChange = (selectedOptions) => {
    const selectedValuesArray = selectedOptions.map((option) => option.value);
    // setSelectedValues(selectedOptions);
    handleFilterChange("companies", selectedValuesArray);
  };
  const handleGeolocationSelectChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "selectAll")) {
      if (selectedOptions.length === getAllGeolocation.length) {
        handleFilterChange("location", []);
      } else {
        handleFilterChange(
          "location",
          getAllGeolocation
            .filter((option) => option.value !== "selectAll")
            .map((option) => option.value)
        );
      }
    } else {
      handleFilterChange(
        "location",
        selectedOptions ? selectedOptions.map((option) => option.value) : []
      );
    }
  };

  return (
    <div className="dropdown filterContent" ref={dropdownRef}>
      <div
        className="filterDropdown drop"
        id="filterDropdown"
        data-bs-toggle="dropdown"
        // data-bs-auto-close="false"
        // aria-expanded="false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
            stroke="#414141"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>{" "}
        Filters
      </div>
      <div className="dropdown-menu" aria-labelledby="filterDropdown">
        <div className="dropdownHeading">
          <p>Filter</p>
          <Link to="" onClick={handleResetFilters}>
            Reset
          </Link>
        </div>
        <div className="dropdownContent" onClick={(e) => e.stopPropagation()}>
          <div className="cardDrop">
            <p className="title">Companies</p>
            <form>
              <div className="row">
                <Select
                  options={getComName}
                  styles={customStyles}
                  className="selectBox"
                  placeholder="Select"
                  name="IndustryList"
                  isMulti
                  value={
                    filters.companies.length > 0
                      ? filters.companies[filters.companies.length - 1].map(
                          (option) => ({
                            value: option,
                            label: option,
                          })
                        )
                      : ""
                  }
                  onChange={handleCompanyNameSelectChange}
                />
              </div>
            </form>
          </div>

          <div className="cardDrop">
            <p className="title">Job Level</p>
            <Select
              options={getJobLevel}
              styles={customStyles}
              className="selectBox"
              placeholder="Select"
              name="IndustryList"
              isMulti
              value={
                filters.jobLevel.length > 0
                  ? filters.jobLevel[filters.jobLevel.length - 1].map(
                      (option) => ({
                        value: option,
                        label: option,
                      })
                    )
                  : ""
              }
              onChange={handleJobLevelSelectChange}
            />
          </div>
          <div className="cardDrop">
            <p className="title">Job Function</p>
            <Select
              options={getJobFunction}
              styles={customStyles}
              className="selectBox"
              placeholder="Select"
              name="IndustryList"
              isMulti
              value={
                filters.jobFunction.length > 0
                  ? filters.jobFunction[filters.jobFunction.length - 1].map(
                      (option) => ({
                        value: option,
                        label: option,
                      })
                    )
                  : ""
              }
              onChange={handleJobFunctionSelectChange}
            />
          </div>
          <div className="cardDrop">
            <p className="title">Company Revenue</p>

            <Select
              options={getRevenue}
              styles={customStyles}
              className="selectBox"
              placeholder="Select"
              name="IndustryList"
              isMulti
              value={
                filters.companyRevenue.length > 0
                  ? filters.companyRevenue[
                      filters.companyRevenue.length - 1
                    ].map((option) => ({
                      value: option,
                      label: option,
                    }))
                  : ""
              }
              onChange={handleCompanyRevenueSelectChange}
            />
          </div>
          <div className="cardDrop">
            <p className="title">Geolocation</p>
            <Select
              options={getAllGeolocation}
              styles={customStyles}
              className="selectBox"
              placeholder="Select"
              name="location"
              value={
                filters.location.length > 0
                  ? filters.location[filters.location.length - 1].map(
                      (option) => ({
                        value: option,
                        label: option,
                      })
                    )
                  : ""
              }
              onChange={handleGeolocationSelectChange}
              isMulti
            />
          </div>
        </div>
        <div className="filterFooter">
          <button type="submit" className="btn" onClick={handleApplyFilters}>
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProspectFilter;
