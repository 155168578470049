import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getUserDetails,
  updateUserDetails,
  updateUserEmail,
  updateUserPhoneNumber,
} from "../../../context/actions/User";
import ProfilePicture from "../Modals/ProfilePicture/ProfilePicture";
import "./ProfileSettings.scss";
import AddProfilePicture from "../Modals/AddProfilePicture/AddProfilePicture";
import PhoneInput from "react-phone-input-2";
import { Formik, Form, Field } from "formik";
import ReactGoogleAutocomplete from "react-google-autocomplete";

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  //below use state is for opening the AddProfilePicture Model
  const [showAddProfileModal, setShowAddProfileModal] = useState(false);
  // useStae for userdata from the api call in the useEffect
  const [getUserData, setGetUserData] = useState([]);
  //update email state
  const [updateEmail, setUpdateEmail] = useState("");
  //below useState  is for managing the upload the image
  const [selectedFile, setSelectedFile] = useState(false);
  // state variables for
  const [validationErrors, setValidationErrors] = useState({
    linkedin_url: "",
    instagram_url: "",
    facebook_url: "",
    twitter_url: "",
    first_name: "",
    last_name: "",
    company_name: "",
  });

  // State to track if any changes have been made
  const [changesMade, setChangesMade] = useState(false);
  const [emailChangesMade, setEmailChangesMade] = useState(false);
  const isStaff = userData.userInfo.is_staff;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //Here in below we are taking the data from the get usr details api
  useEffect(() => {
    if (userId) {
      const payload = {
        userId: userId,
      };
      dispatch(
        getUserDetails(payload, token, (result) => {
          setGetUserData(result);
          setUpdateEmail(result.email || "");
        })
      );
    }
  }, []);

  //manahge the change of input
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setGetUserData((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    // Set changesMade to true when input changes
    setChangesMade(true);

    // Reset validation error for the input being changed
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleLocationChange = (place) => {
    const { lat, lng } = place.geometry.location;
    const country =
      place.address_components.find((component) =>
        component.types.includes("country")
      )?.long_name || "";

    const newLocationData = {
      location: place.formatted_address,
      latitude: lat(),
      longitude: lng(),
      country: country,
    };

    setGetUserData((prevDetails) => {
      const updatedDetails = { ...prevDetails, ...newLocationData };
      return updatedDetails;
    });
    setChangesMade(true);
  };

  const initialValues = {
    phone_number: getUserData?.phone_number ? getUserData?.phone_number : "",
  };
  const handleUpdateDetails = (e) => {
    e.preventDefault();

    // Validation logic for LinkedIn URL
    let errors = {};
    if (
      getUserData.facebook_url &&
      !/^(https?:\/\/)?(www\.)?facebook\.com\/.*$/.test(
        getUserData.facebook_url
      )
    ) {
      errors = {
        ...errors,
        facebook_url:
          "Invalid Facebook URL. Please provide a URL in the format: https://www.facebook.com/yourpage",
      };
    }
    // Validation logic for Instagram URL

    if (
      getUserData.instagram_url &&
      !/^(https?:\/\/)?(www\.)?instagram\.com\/.*$/.test(
        getUserData.instagram_url
      )
    ) {
      errors = {
        ...errors,
        instagram_url:
          "Invalid Instagram URL. Please provide a URL in the format: https://www.instagram.com/yourpage",
      };
    }

    // Validation logic for Twitter URL
    if (
      getUserData.twitter_url &&
      !/^(https?:\/\/)?(www\.)?twitter\.com\/.*$/.test(getUserData.twitter_url)
    ) {
      errors = {
        ...errors,
        twitter_url:
          "Invalid Twitter URL. Please provide a URL in the format: https://twitter.com/yourhandle",
      };
    }

    // Validation logic for LinkedIn URL
    if (
      getUserData.linkedin_url &&
      !/^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/.test(
        getUserData.linkedin_url
      )
    ) {
      errors = {
        ...errors,
        linkedin_url:
          "Invalid LinkedIn URL. Please provide a URL in the format: https://www.linkedin.com/in/yourprofile",
      };
    }
    // Validation logic for First Name
    if (!getUserData.first_name) {
      errors = {
        ...errors,
        first_name: "First Name is required",
      };
    }

    // Validation logic for Last Name
    if (!getUserData.last_name) {
      errors = {
        ...errors,
        last_name: "Last Name is required",
      };
    }

    // Validation logic for Company Name
    if (!getUserData.company_name) {
      errors = {
        ...errors,
        company_name: "Company Name is required",
      };
    }

    // Set validation errors
    setValidationErrors(errors);

    // If there are validation errors, return
    if (Object.keys(errors).length > 0) {
      return;
    }

    // If no validation errors, clear all validation errors
    setValidationErrors({
      linkedin_url: "",
      instagram_url: "",
      facebook_url: "",
      twitter_url: "",
      first_name: "",
      last_name: "",
      company_name: "",
    });

    // Proceed with updating data
    const payload = {
      getUserData: getUserData,
    };
    if (userId) {
      dispatch(updateUserDetails(payload, token, () => {}));
    }

    // After updating, set changesMade to false
    setChangesMade(false);
  };

  //to open the modal without backdrop issue
  const handleShowAddProfileModal = (e) => {
    e.preventDefault();

    setShowAddProfileModal(true);
  };

  const hanldeUpdateEmailChange = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    const payload = {
      email: updateEmail,
      user_id: userId,
    };
    if (userId) {
      dispatch(
        updateUserEmail(payload, token, (res) => {
          if (res.status === 200) {
            setUpdateEmail("");
            setEmailChangesMade(false); // Reset changesMade after successful update
          }
          const timerEndTime = new Date().getTime() + 180 * 1000;
          localStorage.setItem("otpTimerEndTime", timerEndTime);
          navigate("/email-verfication");
          navigate("/settings-email-verification", { state: payload });
        })
      );
    }
  };

  const onSubmit = (values) => {
    let payload = {
      phone_number: "+" + values.phone_number,
      user_id: userId,
    };

    if (userId) {
      dispatch(
        updateUserPhoneNumber(payload, token, (res) => {
          if (res.status === 200) {
            initialValues.phone_number = "";
          }
          const timerEndTime = new Date().getTime() + 180 * 1000;
          localStorage.setItem("otpTimerEndTime", timerEndTime);
          navigate("/email-verfication");
          navigate("/settings-phone-verification", { state: payload });
        })
      );
    }
  };
  return (
    <>
      <div className="pageHeadingBlock">
        <div className="pageTitle">
          <h3>Settings</h3>
        </div>
      </div>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="contentWrapper profileSettingsPage">
              <div className="row">
                <div className="col-12">
                  <div className="userPersonalDetails">
                    <div className="headingBlock">
                      <h5>Update Personal Details</h5>
                    </div>
                    <div className="contentBlock">
                      <form className="userDetailsUpdate">
                        <div className="row ">
                          <div className="col-12 col-md-6 p-0">
                            <div className="editUserImage">
                              <div className="userImage">
                                {getUserData?.profile_image ? (
                                  <img
                                    src={getUserData?.profile_image}
                                    alt="profile"
                                  />
                                ) : (
                                  <h2
                                    className="userNameLetter"
                                    style={{
                                      background: "$orange3",
                                      width: "16rem",
                                      height: "16rem",
                                      borderRadius: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {getUserData?.first_name
                                      ? getUserData.first_name
                                          .charAt(0)
                                          .toUpperCase()
                                      : null}
                                  </h2>
                                )}
                                {!isStaff && (
                                  <div
                                    className="editImage"
                                    data-bs-toggle="modal"
                                    data-bs-target="#ProfilePictureModal"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                    >
                                      <path
                                        d="M4.16862 15.8364H5.35612L13.502 7.69107L12.3145 6.50365L4.16862 14.649V15.8364ZM2.50195 17.5029V13.9615L13.502 2.98304C13.6686 2.83027 13.8526 2.71223 14.054 2.6289C14.2554 2.54557 14.4672 2.50391 14.6895 2.50391C14.9117 2.50391 15.127 2.54557 15.3353 2.6289C15.5436 2.71223 15.7242 2.83722 15.877 3.00387L17.0228 4.17047C17.1895 4.32323 17.311 4.50378 17.3874 4.7121C17.4638 4.92042 17.502 5.12874 17.502 5.33706C17.502 5.55927 17.4638 5.77106 17.3874 5.97243C17.311 6.17381 17.1895 6.35783 17.0228 6.52448L6.04362 17.5029H2.50195ZM12.8978 7.10778L12.3145 6.50365L13.502 7.69107L12.8978 7.10778Z"
                                        fill="#E55426"
                                      />
                                    </svg>
                                  </div>
                                )}
                                <ProfilePicture
                                  showAddProfileModal={
                                    handleShowAddProfileModal
                                  }
                                  selectedFile={selectedFile}
                                  getUserData={getUserData}
                                />
                                <AddProfilePicture />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 p-0">
                            <div className="form-group col-12">
                              <label>
                                First Name{" "}
                                <span className="required">&#42;</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="first_name"
                                placeholder="Enter First Name"
                                value={getUserData?.first_name}
                                onChange={handleInputChange}
                              />
                              {validationErrors.first_name && (
                                <span className="text-danger">
                                  {validationErrors.first_name}
                                </span>
                              )}
                            </div>
                            <div className="form-group col-12">
                              <label>
                                Last Name{" "}
                                <span className="required">&#42;</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="last_name"
                                placeholder="Enter Last Name"
                                value={getUserData?.last_name}
                                onChange={handleInputChange}
                              />
                              {validationErrors.last_name && (
                                <span className="text-danger">
                                  {validationErrors.last_name}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-12 col-md-6">
                            <label>Designation</label>
                            <input
                              type="text"
                              className="form-control"
                              name="designation"
                              placeholder="Enter Designation"
                              value={getUserData?.designation}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="form-group col-12 col-md-6">
                            <label>
                              Company Name{" "}
                              <span className="required">&#42;</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="company_name"
                              placeholder="Enter Company Name"
                              value={getUserData?.company_name}
                              onChange={handleInputChange}
                            />
                            {validationErrors.company_name && (
                              <span className="text-danger">
                                {validationErrors.company_name}
                              </span>
                            )}
                          </div>
                          <div className="form-group col-12 col-md-6">
                            <label>Location</label>
                            <ReactGoogleAutocomplete
                              apiKey={"AIzaSyD6Jn7KB6fcGgGSPklpfK66qlySPClNZ7w"}
                              onPlaceSelected={(place) =>
                                handleLocationChange(place)
                              }
                              defaultValue={getUserData?.location}
                              className="form-control"
                            />
                            {/* <input
                              type="text"
                              className="form-control"
                              name="location"
                              placeholder="Enter Location"
                              value={getUserData?.location}
                              onChange={handleInputChange}
                            /> */}
                          </div>
                          <div className="form-group col-12 col-md-6">
                            <label>Home Address</label>
                            <input
                              type="text"
                              className="form-control"
                              name="home_address"
                              placeholder="Enter Home Address"
                              value={getUserData?.home_address}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="form-group col-12 col-md-6">
                            <label>Work Address</label>
                            <input
                              type="text"
                              className="form-control"
                              name="work_address"
                              placeholder="Enter Work Address"
                              value={getUserData?.work_address}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="form-group col-12 col-md-6">
                            <label>Billing Address</label>
                            <input
                              type="text"
                              className="form-control"
                              name="billing_address"
                              placeholder="Enter Billing Address"
                              value={getUserData?.billing_address}
                              onChange={handleInputChange}
                            />
                          </div>
                          {/* <div className="form-group col-12 col-md-6">
                            <label>Facebook URL</label>
                            <input
                              type="text"
                              className="form-control"
                              name="facebook_url"
                              placeholder="Enter Facebook URL"
                              value={getUserData?.facebook_url}
                              onChange={handleInputChange}
                            />
                            {validationErrors.facebook_url && (
                              <span className="text-danger">
                                {validationErrors.facebook_url}
                              </span>
                            )}
                          </div> */}
                          {/* <div className="form-group col-12 col-md-6">
                            <label>Instagram URL</label>
                            <input
                              type="text"
                              className="form-control"
                              name="instagram_url"
                              placeholder="Enter Instagram URL"
                              value={getUserData?.instagram_url}
                              onChange={handleInputChange}
                            />
                            {validationErrors.instagram_url && (
                              <span className="text-danger">
                                {validationErrors.instagram_url}
                              </span>
                            )}
                          </div> */}
                          <div className="form-group col-12 col-md-6">
                            <label>LinkedIn URL</label>
                            <input
                              type="text"
                              className="form-control"
                              name="linkedin_url"
                              placeholder="Enter LinkedIn URL"
                              value={getUserData?.linkedin_url}
                              onChange={handleInputChange}
                            />
                            {validationErrors.linkedin_url && (
                              <span className="text-danger">
                                {validationErrors.linkedin_url}
                              </span>
                            )}
                          </div>
                          <div className="form-group col-12 col-md-6">
                            <label>Twitter URL</label>
                            <input
                              type="text"
                              className="form-control"
                              name="twitter_url"
                              placeholder="Enter Twitter URL"
                              value={getUserData?.twitter_url}
                              onChange={handleInputChange}
                            />
                            {validationErrors.twitter_url && (
                              <span className="text-danger">
                                {validationErrors.twitter_url}
                              </span>
                            )}
                          </div>
                          <div className="form-group formSubmit col-12">
                            <button
                              type="submit"
                              disabled={!changesMade || isStaff} // Disable button if no changes made
                              className="btn btn-update d-flex"
                              onClick={handleUpdateDetails}
                            >
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M4.42439 17.0836C4.00344 17.0836 3.64714 16.9378 3.35547 16.6461C3.0638 16.3545 2.91797 15.9981 2.91797 15.5772V4.42341C2.91797 4.00247 3.0638 3.64616 3.35547 3.35449C3.64714 3.06283 4.00344 2.91699 4.42439 2.91699H13.3683C13.5691 2.91699 13.7622 2.95599 13.9476 3.03399C14.1329 3.11198 14.294 3.21935 14.4308 3.3561L16.6455 5.57083C16.7822 5.70758 16.8896 5.86864 16.9676 6.05401C17.0456 6.23937 17.0846 6.43248 17.0846 6.63333V15.5772C17.0846 15.9981 16.9388 16.3545 16.6471 16.6461C16.3554 16.9378 15.9991 17.0836 15.5782 17.0836H4.42439ZM15.8346 6.54197L13.4596 4.16697H4.42439C4.34959 4.16697 4.28816 4.19101 4.24007 4.2391C4.19199 4.28718 4.16795 4.34862 4.16795 4.42341V15.5772C4.16795 15.652 4.19199 15.7134 4.24007 15.7615C4.28816 15.8096 4.34959 15.8336 4.42439 15.8336H15.5782C15.653 15.8336 15.7144 15.8096 15.7625 15.7615C15.8106 15.7134 15.8346 15.652 15.8346 15.5772V6.54197ZM10.0013 14.3913C10.5782 14.3913 11.0696 14.1883 11.4756 13.7823C11.8816 13.3763 12.0846 12.8849 12.0846 12.308C12.0846 11.7311 11.8816 11.2396 11.4756 10.8336C11.0696 10.4277 10.5782 10.2247 10.0013 10.2247C9.42436 10.2247 8.93292 10.4277 8.52695 10.8336C8.12096 11.2396 7.91797 11.7311 7.91797 12.308C7.91797 12.8849 8.12096 13.3763 8.52695 13.7823C8.93292 14.1883 9.42436 14.3913 10.0013 14.3913ZM6.07501 8.23747H11.4115C11.6263 8.23747 11.8055 8.16563 11.9492 8.02193C12.0929 7.87824 12.1647 7.69902 12.1647 7.48428V6.07403C12.1647 5.8593 12.0929 5.68008 11.9492 5.53639C11.8055 5.39269 11.6263 5.32085 11.4115 5.32085H6.07501C5.86027 5.32085 5.68106 5.39269 5.53736 5.53639C5.39367 5.68008 5.32182 5.8593 5.32182 6.07403V7.48428C5.32182 7.69902 5.39367 7.87824 5.53736 8.02193C5.68106 8.16563 5.86027 8.23747 6.07501 8.23747ZM4.16795 6.54197V15.8336V4.16697V6.54197Z"
                                  fill="white"
                                />
                              </svg>{" "} */}
                              {/* <i class="fa fa-save" ></i> */}
                              Update
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <div className="contentWrapper sideContentWrapper profileSettingsPage">
              <div className="contentCard">
                <div className="cardHeading">
                  <h5>Update Business Email</h5>
                </div>
                <div className="cardContent">
                  <form className="businessUpdate">
                    <div className="row">
                      <div className="form-group col-12">
                        <label>
                          New Business Email{" "}
                          <span className="required">&#42;</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="updateEmail"
                          placeholder="Enter New Business Email"
                          value={updateEmail}
                          onChange={(e) => {
                            setUpdateEmail(e.target.value);
                            setEmailChangesMade(true); // Set changesMade to true when input changes
                          }}
                        />
                      </div>
                      <div className="form-group formSubmit col-12">
                        <button
                          type="submit"
                          className="btn btn-update d-flex"
                          disabled={
                            !emailChangesMade || !updateEmail || isStaff
                          } // Disable button if no changes or updateEmail is empty
                          onClick={hanldeUpdateEmailChange}
                        >
                          {" "}
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M4.42439 17.0836C4.00344 17.0836 3.64714 16.9378 3.35547 16.6461C3.0638 16.3545 2.91797 15.9981 2.91797 15.5772V4.42341C2.91797 4.00247 3.0638 3.64616 3.35547 3.35449C3.64714 3.06283 4.00344 2.91699 4.42439 2.91699H13.3683C13.5691 2.91699 13.7622 2.95599 13.9476 3.03399C14.1329 3.11198 14.294 3.21935 14.4308 3.3561L16.6455 5.57083C16.7822 5.70758 16.8896 5.86864 16.9676 6.05401C17.0456 6.23937 17.0846 6.43248 17.0846 6.63333V15.5772C17.0846 15.9981 16.9388 16.3545 16.6471 16.6461C16.3554 16.9378 15.9991 17.0836 15.5782 17.0836H4.42439ZM15.8346 6.54197L13.4596 4.16697H4.42439C4.34959 4.16697 4.28816 4.19101 4.24007 4.2391C4.19199 4.28718 4.16795 4.34862 4.16795 4.42341V15.5772C4.16795 15.652 4.19199 15.7134 4.24007 15.7615C4.28816 15.8096 4.34959 15.8336 4.42439 15.8336H15.5782C15.653 15.8336 15.7144 15.8096 15.7625 15.7615C15.8106 15.7134 15.8346 15.652 15.8346 15.5772V6.54197ZM10.0013 14.3913C10.5782 14.3913 11.0696 14.1883 11.4756 13.7823C11.8816 13.3763 12.0846 12.8849 12.0846 12.308C12.0846 11.7311 11.8816 11.2396 11.4756 10.8336C11.0696 10.4277 10.5782 10.2247 10.0013 10.2247C9.42436 10.2247 8.93292 10.4277 8.52695 10.8336C8.12096 11.2396 7.91797 11.7311 7.91797 12.308C7.91797 12.8849 8.12096 13.3763 8.52695 13.7823C8.93292 14.1883 9.42436 14.3913 10.0013 14.3913ZM6.07501 8.23747H11.4115C11.6263 8.23747 11.8055 8.16563 11.9492 8.02193C12.0929 7.87824 12.1647 7.69902 12.1647 7.48428V6.07403C12.1647 5.8593 12.0929 5.68008 11.9492 5.53639C11.8055 5.39269 11.6263 5.32085 11.4115 5.32085H6.07501C5.86027 5.32085 5.68106 5.39269 5.53736 5.53639C5.39367 5.68008 5.32182 5.8593 5.32182 6.07403V7.48428C5.32182 7.69902 5.39367 7.87824 5.53736 8.02193C5.68106 8.16563 5.86027 8.23747 6.07501 8.23747ZM4.16795 6.54197V15.8336V4.16697V6.54197Z"
                              fill="white"
                            />
                          </svg>{" "} */}
                          {/* <i class="fa fa-save"></i> */}
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="contentCard">
                <div className="cardHeading">
                  <h5>Update Phone Number</h5>
                </div>
                <div className="cardContent">
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={registerValidation}
                    onSubmit={onSubmit}
                  >
                    {({ values, handleChange, handleBlur }) => (
                      <Form className="businessUpdate">
                        <div className="row">
                          <div className="form-group col-12">
                            <label>
                              New Phone Number{" "}
                              <span className="required">&#42;</span>
                            </label>
                            <Field name="phone_number">
                              {({ field, form }) => (
                                <div>
                                  <PhoneInput
                                    inputProps={{
                                      name: "phone_number",
                                      required: true,
                                      autoFocus: true,
                                    }}
                                    country={"US"} // Set your default country
                                    value={
                                      getUserData?.phone_number
                                        ? getUserData?.phone_number
                                        : field.value
                                    }
                                    onChange={(value) =>
                                      form.setFieldValue("phone_number", value)
                                    }
                                  />
                                  {/* <ErrorMessage
                                    name="phone_number"
                                    component="div"
                                    className="error-message"
                                  /> */}
                                </div>
                              )}
                            </Field>
                          </div>
                          <div className="form-group formSubmit col-12">
                            <button
                              type="submit"
                              className="btn btn-update d-flex"
                              disabled={!values.phone_number || isStaff}
                            >
                              {" "}
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  d="M4.42439 17.0836C4.00344 17.0836 3.64714 16.9378 3.35547 16.6461C3.0638 16.3545 2.91797 15.9981 2.91797 15.5772V4.42341C2.91797 4.00247 3.0638 3.64616 3.35547 3.35449C3.64714 3.06283 4.00344 2.91699 4.42439 2.91699H13.3683C13.5691 2.91699 13.7622 2.95599 13.9476 3.03399C14.1329 3.11198 14.294 3.21935 14.4308 3.3561L16.6455 5.57083C16.7822 5.70758 16.8896 5.86864 16.9676 6.05401C17.0456 6.23937 17.0846 6.43248 17.0846 6.63333V15.5772C17.0846 15.9981 16.9388 16.3545 16.6471 16.6461C16.3554 16.9378 15.9991 17.0836 15.5782 17.0836H4.42439ZM15.8346 6.54197L13.4596 4.16697H4.42439C4.34959 4.16697 4.28816 4.19101 4.24007 4.2391C4.19199 4.28718 4.16795 4.34862 4.16795 4.42341V15.5772C4.16795 15.652 4.19199 15.7134 4.24007 15.7615C4.28816 15.8096 4.34959 15.8336 4.42439 15.8336H15.5782C15.653 15.8336 15.7144 15.8096 15.7625 15.7615C15.8106 15.7134 15.8346 15.652 15.8346 15.5772V6.54197ZM10.0013 14.3913C10.5782 14.3913 11.0696 14.1883 11.4756 13.7823C11.8816 13.3763 12.0846 12.8849 12.0846 12.308C12.0846 11.7311 11.8816 11.2396 11.4756 10.8336C11.0696 10.4277 10.5782 10.2247 10.0013 10.2247C9.42436 10.2247 8.93292 10.4277 8.52695 10.8336C8.12096 11.2396 7.91797 11.7311 7.91797 12.308C7.91797 12.8849 8.12096 13.3763 8.52695 13.7823C8.93292 14.1883 9.42436 14.3913 10.0013 14.3913ZM6.07501 8.23747H11.4115C11.6263 8.23747 11.8055 8.16563 11.9492 8.02193C12.0929 7.87824 12.1647 7.69902 12.1647 7.48428V6.07403C12.1647 5.8593 12.0929 5.68008 11.9492 5.53639C11.8055 5.39269 11.6263 5.32085 11.4115 5.32085H6.07501C5.86027 5.32085 5.68106 5.39269 5.53736 5.53639C5.39367 5.68008 5.32182 5.8593 5.32182 6.07403V7.48428C5.32182 7.69902 5.39367 7.87824 5.53736 8.02193C5.68106 8.16563 5.86027 8.23747 6.07501 8.23747ZM4.16795 6.54197V15.8336V4.16697V6.54197Z"
                                  fill="white"
                                />
                              </svg>{" "} */}
                              {/* <i class="fa fa-save"></i> */}
                              Update
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              {!isStaff && (
                <div className="contentCard">
                  <div className="cardHeading">
                    <h5>Reset Password</h5>
                  </div>
                  <div className="cardContent">
                    <p className="m-0">
                      <Link to="/change-password">Click Here </Link> to Update
                      your existing password.
                      {/* A reset password link will be sent to your
                      business email.{" "} */}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSettings;
