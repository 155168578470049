import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import handIcon from "../../assets/images/handIcon.png";
import "./Subscription.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserSubscriptionPlanDetails,
  getsubscriptionDetails,
} from "../../context/actions/User";

const Subscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);

  const udesubscriptiondata = useSelector(
    (state) => state?.userSubscriptionData?.userSubscrptionData?.data
  );

  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  //useState for getting all plan information
  const [getSubScriptionDetails, setGetSubScriptionDetails] = useState([]);
  //useState for user's already having plan data
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState({});

  // State variable to track whether subscription details have been fetched
  const [subscriptionDetailsFetched, setSubscriptionDetailsFetched] =
    useState(false);

  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result.status === 200) {
            setGetUserSubscriptionData(result?.data);
          } else if (result.status === 404) {
            // navigate("/subscription-plans");
            // toast("Please Buy Subscription Package!", { autoClose: 1200 });
          }
        })
      );
    }
  }, []);

  //below useEffect is for getting all plan information
  useEffect(() => {
    dispatch(
      getsubscriptionDetails(token, (result) => {
        setGetSubScriptionDetails(result?.data?.plans);
        // Set subscriptionDetailsFetched to true after fetching subscription details
        setSubscriptionDetailsFetched(true);
      })
    );
  }, []);

  const handleRedirect = (data) => {
    navigate("/payments", { state: data });
  };

  const mdWidth = Math.floor(12 / getSubScriptionDetails.length);

  return (
    <>
      <div className="pageHeadingBlock">
        <div className="pageTitle">
          <h3>
          Select the Ideal Subscription for Your Journey to Success <img src={handIcon} alt="" />
          </h3>
        </div>
        {!userData?.userInfo?.is_staff &&
          getUserSubscriptionData &&
          Object.keys(getUserSubscriptionData).length > 0 && (
            <div className="buyCredits mb-2">
              <Link to="/credit-payments" className="btn btnPrimaryOutline">
                Buy Additional Credits
              </Link>
            </div>
          )}
      </div>

      <div className="pageContentBlock planSubscritions">
        <div className="row">
          {getSubScriptionDetails.map((data) => (
            <div
              key={data.id}
              className={`col-12 col-sm-6 col-md-6 col-lg-${mdWidth}`}
            >
              <div className="planCard">
                <div className="planBillingDetails">
                  <div className="planName text-center">
                    <h3>{data.package_name}</h3>
                  </div>
                  <div className="planPrice">
                    <sup>$</sup>
                    <h2>
                      {data.price} <span>Billed Annually</span>
                    </h2>
                  </div>
                </div>
                {/* <div className="plans-btn-block"> */}
                  <div className="planFeatures">
                    <p>This Pack Includes:</p>
                    <ul>
                      <li className="yesPlans">
                        {data.data_download_credits || "0"} Data Download Credits
                      </li>
                      <li className="yesPlans">
                        {data.number_of_searches || "0"} Number of Searches
                      </li>
                      <li className="yesPlans">
                        Upto {data.page_visibility || "0"} Page Visibility
                      </li>
                      <li className="yesPlans">
                        Additional Credits at ${data.additional_credit || "0"} per
                        credit
                      </li>
                      <li className="yesPlans">
                        {data.per_day_data_download_limit || "0"} data download
                        per day
                      </li>
                      <li
                        className={
                          data.email_support === "1"
                            ? "yesPlans"
                            : "noPlans"
                        }
                      >
                        Email support with Dedicated Account manager
                      </li>
                      <li className="yesPlans">
                      {data.prospect_download_credits || "0"} prospect download credit 
                      </li>
                      <li className="yesPlans">
                        Carry Forward Credit: {data.carry_forward_credit || "0"} 
                      </li>
                      <li className="yesPlans">{data.license || "0"} License</li>
                      <li
                        className={
                          data.content_syndication === "1"
                            ? "yesPlans"
                            : "noPlans"
                        }
                      >
                        Content Syndication(Single/Multiple Touch)
                      </li>
                      <li
                        className={
                          data.market_qualified_lead === "1"
                            ? "yesPlans"
                            : "noPlans"
                        }
                      >
                        Market Qualified Lead (1 or 2 CQs)
                      </li>
                      <li
                        className={
                          data.high_qualified_lead === "1"
                            ? "yesPlans"
                            : "noPlans"
                        }
                      >
                        High Qualified Leads
                      </li>
                      <li
                        className={data.bant_vpi === "1" ? "yesPlans" : "noPlans"}
                      >
                        BANT+VPI
                      </li>
                      <li
                        className={
                          data.install_base_program === "1"
                            ? "yesPlans"
                            : "noPlans"
                        }
                      >
                        Install Base Program
                      </li>
                      <li
                        className={
                          data.account_profiling === "1" ? "yesPlans" : "noPlans"
                        }
                      >
                        Account Profiling
                      </li>
                      <li
                        className={
                          data.buyer_intent_layer === "1" ? "yesPlans" : "noPlans"
                        }
                      >
                        Buyer Intent Layer
                      </li>
                      <li
                        className={
                          data.webinar_ondemand === "1" ? "yesPlans" : "noPlans"
                        }
                      >
                        Webinar/OnDemand
                      </li>
                      <li className="yesPlans">
                        Additional Opt-in Leads (B2B Services):{" "}
                        {data.additional_opt_lead || "0"} leads
                      </li>
                      <li className="yesPlans">
                        Additional Leads: {data.additional_lead || "0"} %
                      </li>
                      <li
                        className={
                          data.replacement_lead === "1" ? "yesPlans" : "noPlans"
                        }
                      >
                        Replacement Leads
                      </li>
                      <li className="yesPlans">Over & Above CPL: {data.cpl}</li>
                    </ul>
                  </div>

                    {data?.package_name ===
                  getUserSubscriptionData?.user_package_name ? (
                    <div className="currentPlanMessage text-center p-5">
                      <strong style={{ color: "#E55426" }}>
                        This is your current plan !
                      </strong>
                    </div>
                  ) : (
                    <button
                      className="getStarted btn"
                      disabled={userData?.userInfo?.is_staff}
                      onClick={() => handleRedirect(data)}
                    >
                      Get Started Now
                    </button>
                  )}
                {/* </div> */}
                {/* <div className="planFeatures">
                  <p>This Pack Includes:</p>
                  <ul>
                    <li className="yesPlans">
                      {data.data_download_credits || "0"} Data Download Credits
                    </li>
                    <li className="yesPlans">
                      {data.number_of_searches || "0"} Number of Searches
                    </li>
                    <li className="yesPlans">
                      Upto {data.page_visibility || "0"} Page Visibility
                    </li>
                    <li className="yesPlans">
                      Additional Credits at ${data.additional_credit || "0"} per
                      credit
                    </li>
                    <li className="yesPlans">
                      {data.per_day_data_download_limit || "0"} data download
                      per day
                    </li>
                    <li
                      className={
                        data.email_support === "1"
                          ? "yesPlans"
                          : "noPlans"
                      }
                    >
                      Email support with Dedicated Account manager
                    </li>
                    <li className="yesPlans">
                    {data.prospect_download_credits || "0"} prospect download credit 
                    </li>
                    <li className="yesPlans">
                     Carry Forward Credit: {data.carry_forward_credit || "0"} 
                    </li>
                    <li className="yesPlans">{data.license || "0"} License</li>
                    <li
                      className={
                        data.content_syndication === "1"
                          ? "yesPlans"
                          : "noPlans"
                      }
                    >
                      Content Syndication(Single/Multiple Touch)
                    </li>
                    <li
                      className={
                        data.market_qualified_lead === "1"
                          ? "yesPlans"
                          : "noPlans"
                      }
                    >
                      Market Qualified Lead (1 or 2 CQs)
                    </li>
                    <li
                      className={
                        data.high_qualified_lead === "1"
                          ? "yesPlans"
                          : "noPlans"
                      }
                    >
                      High Qualified Leads
                    </li>
                    <li
                      className={data.bant_vpi === "1" ? "yesPlans" : "noPlans"}
                    >
                      BANT+VPI
                    </li>
                    <li
                      className={
                        data.install_base_program === "1"
                          ? "yesPlans"
                          : "noPlans"
                      }
                    >
                      Install Base Program
                    </li>
                    <li
                      className={
                        data.account_profiling === "1" ? "yesPlans" : "noPlans"
                      }
                    >
                      Account Profiling
                    </li>
                    <li
                      className={
                        data.buyer_intent_layer === "1" ? "yesPlans" : "noPlans"
                      }
                    >
                      Buyer Intent Layer
                    </li>
                    <li
                      className={
                        data.webinar_ondemand === "1" ? "yesPlans" : "noPlans"
                      }
                    >
                      Webinar/OnDemand
                    </li>
                    <li className="yesPlans">
                      Additional Opt-in Leads (B2B Services):{" "}
                      {data.additional_opt_lead || "0"} leads
                    </li>
                    <li className="yesPlans">
                      Additional Leads: {data.additional_lead || "0"} %
                    </li>
                    <li
                      className={
                        data.replacement_lead === "1" ? "yesPlans" : "noPlans"
                      }
                    >
                      Replacement Leads
                    </li>
                    <li className="yesPlans">Over & Above CPL: {data.cpl}</li>
                  </ul>
                </div> */}

                {/* {data?.package_name ===
                getUserSubscriptionData?.user_package_name ? (
                  <div className="currentPlanMessage text-center pt-4">
                    <strong style={{ color: "#E55426" }}>
                      This is your current plan !
                    </strong>
                  </div>
                ) : (
                  <button
                    className="getStarted btn"
                    disabled={userData?.userInfo?.is_staff}
                    onClick={() => handleRedirect(data)}
                  >
                    Get Started Now
                  </button>
                )} */}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Render the note after subscription details are fetched */}
      {subscriptionDetailsFetched && (
        <div className="orderSummary paymentCard">
          <div className="contentBlock">
            <div className="choosedPlan">
              <div className="planName">
                <div className="note">
                  <p>
                    Note:
                    <br /> 
                    <br /> 1. Forward your Additional Opt-in Leads Balance
                    maximum upto 3 months Additional Opt-in Leads will be
                    provided as per the services alloted in the plans.
                    <br /> 2. Additional Opt-in Leads will be delivered within
                    30 days from the day service request has beem initiated.{" "}
                    <br />
                    3. You can carry from the date of purchase of your
                    subscription.
                    <br /> 4. Replacement Leads to be provided within 48 hours
                    from the date of request.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Subscription;
