import "rc-slider/assets/index.css";
import React from "react";
import Table from "react-bootstrap/Table";
import "./MyDownloadList.scss";

const DownloadTable = ({
  handleSort,
  sortedData,
  sortBy,
  selectedRows,
  onCheckboxChange,
  handleindividualDownload,
  selectAll,
  handleSelectAll,
}) => {
  return (
    <Table>
      <thead
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <tr>
          <th>
            <div className="checkBox">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </div>
          </th>
          <th>
            File Name{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() => handleSort("file_name")}
            >
              <path
                d={
                  sortBy.desc
                    ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                    : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                }
                stroke="#667085"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </th>
          <th>Data Count</th>
          <th>
            Downloaded Date{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() => handleSort("date")}
            >
              <path
                d={
                  sortBy.desc
                    ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                    : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                }
                stroke="#667085"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </th>
          <th>
            Type{" "}
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() => handleSort("list_type")}
            >
              <path
                d={
                  sortBy.desc
                    ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                    : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                }
                stroke="#667085"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg> */}
          </th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {sortedData.length > 0 ? (
          sortedData?.map((data, index) => (
            <tr
              key={data.id}
              onClick={() =>
                onCheckboxChange(data.id, data.list_type, data.exfile)
              }
            >
              <td>
                <div className="checkBox">
                  <input
                    type="checkbox"
                    checked={selectedRows.some((row) => row.id === data.id)}
                    onChange={() =>
                      onCheckboxChange(data.id, data.list_type, data.exfile)
                    }
                  />
                </div>
              </td>
              <td>
                {data.file_name}{" "}
                <span className="ticketDate">
                  Exported By {data?.staff_name ? data?.staff_name : "Admin"}
                </span>
              </td>
              <td>{data.downloadCount}</td>
              <td>{data.date} </td>
              <td>{data.list_type === "ICP" ? "VAIS" : data.list_type}</td>
              <td>
                <svg
                  fill="#000000"
                  width="20px"
                  height="20px"
                  viewBox="0 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() =>
                    handleindividualDownload(data.id, data.list_type)
                  }
                  // onClick={() => handleindividualDownload(data.id)}
                  className={selectedRows.length > 1 ? "disabled-svg" : ""}
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {/* <title>download</title>{" "} */}
                    <path d="M18.313 13.625h-4.031v-6.594c0-0.563-0.469-1.031-1.031-1.031h-4.031c-0.594 0-1.063 0.469-1.063 1.031v6.594h-4.031c-0.531 0-0.719 0.344-0.313 0.75l6.688 6.656c0.188 0.188 0.438 0.281 0.719 0.281s0.563-0.094 0.75-0.281l6.656-6.656c0.375-0.406 0.25-0.75-0.313-0.75zM0 18.344v7.125c0 0.313 0.156 0.5 0.5 0.5h21.375c0.344 0 0.531-0.188 0.531-0.5v-7.125c0-0.313-0.25-0.531-0.531-0.531h-2.031c-0.281 0-0.531 0.25-0.531 0.531v4.531h-16.25v-4.531c0-0.313-0.219-0.531-0.5-0.531h-2.063c-0.281 0-0.5 0.25-0.5 0.531z"></path>{" "}
                  </g>
                </svg>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td className="text-center" colSpan={12}>
              No Records Found
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default DownloadTable;
