import React from "react";
import { useSelector } from "react-redux";
import "./loading.scss";

function Loader() {
  const loading = useSelector((state) => state.loading.isLoading);
  // return <>{loading && <h1>Loading......</h1>}</>;
  return (
    <>
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
}

export default Loader;
