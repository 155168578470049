import React from "react";
import { Button, Modal } from "react-bootstrap";

const SearchModel = ({ isDialogOpen, handleClose, message }) => {
  return (
    <div>
      {" "}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isDialogOpen}
        onHide={handleClose}
      >
        <Modal.Body>{message}</Modal.Body>
        <div style={{ display: "flex", justifyContent: "center" ,marginTop:"10px"}}>
          <Button
            variant="primary"
            style={{ width: "70px" }}
            onClick={handleClose}
          >
            Ok
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default SearchModel;
