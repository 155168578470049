import React, { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";

const DetailedOverviewChart = ({ profileGraphData, type }) => {
  console.log(profileGraphData);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const chartConfig = useMemo(() => {
    if (!profileGraphData || profileGraphData.length === 0) return null;

    // Step 1: Identify all unique keys from all data entries
    const allKeys = profileGraphData.reduce((acc, item) => {
      Object.keys(item.counts || {}).forEach((key) => {
        if (!acc.includes(key)) {
          acc.push(key);
        }
      });
      return acc;
    }, []);

    // Step 2: Populate missing keys with value 0 for each date entry
    const data = profileGraphData.map((item) => {
      const completeCounts = allKeys.reduce((acc, key) => {
        acc[key] = item.counts[key] !== undefined ? item.counts[key] : 0;
        return acc;
      }, {});
      return {
        date: item.date,
        ...completeCounts,
      };
    });

    const series = allKeys.map((key) => ({
      name: key,
      data: data.map((item) => item[key]),
    }));

    const categories = data.map((item) => item.date);

    const options = {
      chart: {
        type: "bar",
        height: 450,
        stacked: true,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
          export: {
            csv: {
              headerCategory: "Date",
              valueFormatter(y) {
                return y ? y : 0;
              },
            },
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "25%",
          endingShape: "rounded",
          dataLabels: {
            textAnchor: "middle",
            distributed: false,
            rotate: "100%",
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: "12px",
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories,
        tickPlacement: "on",
        title: {
          text: "Date",
          offsetY: isMobile ? -10 : 0,
        },
        labels: {
          show: true,
          rotate: -50,
          rotateAlways: true,
        },
        tooltip: {
          enabled: false, // Disable x-axis tooltip
        },
      },
      yaxis: {
        // tickAmount:5,
        title: {
          text: "Total Count",
        },
        labels: {
          formatter: (val) => Math.round(val),
        },
        forceNiceScale: true,
      },
      legend: {
        show: type !== "industry", // Conditionally show/hide legend based on type
        position: isMobile ? "bottom" : "right",
        offsetX: 0,
        offsetY: isMobile ? 0 : 70,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return Math.round(val); // Format tooltip values to round numbers
          },
        },
        enabled: true, // Enable series tooltip
      },
      colors: [
        "#003f5c",
        "#2f4b7c",
        "#665191",
        "#a05195",
        "#d45087",
        "#f95d6a",
        "#ff7c43",
        "#ffa600",
      ],
    };

    return { options, series };
  }, [profileGraphData, isMobile, type]);

  return (
    <>
      {chartConfig ? (
        <div className="chart-container">
          <Chart
            options={chartConfig.options}
            series={chartConfig.series}
            type="bar"
            height={400}
          />
        </div>
      ) : (
        <div className="no-data">
          <h6>No data</h6>
        </div>
      )}
    </>
  );
};

export default DetailedOverviewChart;
