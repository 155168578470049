import { useEffect } from "react";

const PageNotFound = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/dashboard";
    }, 2000);
  }, []);
  return (
    <div className="dashboard-container">
      <section className="program-section">
        <h1>Page Not Found (404)</h1>
        <p>
          404 not found is an HTTP status code error that indicates the specific
          page you are trying to visit is non-existent. Usually, it occurs when
          the domain points to an incorrect DNS address, files or folders have
          misconfigured permissions, or the system runs a corrupt .
        </p>
      </section>
    </div>
  );
};

export default PageNotFound;
