import React, { useState } from "react";
import "./ExportProspectFile.scss";
import * as XLSX from "xlsx";
import { Button, Modal } from "react-bootstrap";
import successIcon from "../../../../assets/images/successIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { downloadProspectList } from "../../../../context/actions/User";
import Success from "../../../Profile/Modals/Success/Success";
import { useNavigate } from "react-router-dom";

const ExportProspectFile = ({
  selectedRows,
  prospectData,
  showModal,
  onClose,
  type,
}) => {
  const userId = useSelector((state) => state?.user?.userInfo?.user_id);
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const userData = useSelector((state) => state?.user);
  const staffID = userData.userInfo?.user_staff?.user;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState("");
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [exported, setExported] = useState(false); // State to track if exported
  const [navigateTo, setNavigateTo] = useState("");
  const handleExport = (e) => {
    e.preventDefault();

    let payload = {
      id: selectedRows,
      filename: fileName,
      user: userId,
      type: type,
    };

    if (!fileName) {
      setMessage("Please provide a file name.");
      setModal(true);
      return;
    }

    if (!exported) {
      // Check if not already exported
      setExported(true); // Set exported to true
      if (staffID) {
        payload = { ...payload, staff_id:staffID };
      }
      dispatch(
        downloadProspectList(payload, token, (res) => {
          if (res.status === 200) {
            setModal(true);
            setMessage(res.message);
            setNavigateTo("my-download-list");
          } else if (res.status === 400) {
            setModal(true);
            setMessage(res.message);
          }
        })
      );
    }
  };

  const onCloses = () => {
    setModal(false);
    if (navigateTo === "my-download-list") {
      navigate("/my-download-list");
    }
  };

  return (
    <>
      <Modal className="ExportModal" show={showModal} onHide={onClose} centered>
        <Modal.Body className="modal-body text-center">
          <h2>File Name</h2>
          <p>Provide a meaningful name to your export file:</p>
          <form>
            <div className="row">
              <div className="form-group col-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter File Name"
                  name="fileName"
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                />
              </div>
              <div className="form-group buttonGroup m-0 col-12">
                <Button
                  type="button"
                  className="btn btnPrimaryOutline"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="btn"
                  onClick={handleExport}
                  disabled={exported} // Disable button if already exported
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M7.16675 14.1667L10.5001 17.5M10.5001 17.5L13.8334 14.1667M10.5001 17.5V10M17.1667 13.9524C18.1847 13.1117 18.8334 11.8399 18.8334 10.4167C18.8334 7.88536 16.7814 5.83333 14.2501 5.83333C14.068 5.83333 13.8976 5.73833 13.8052 5.58145C12.7185 3.73736 10.7121 2.5 8.41675 2.5C4.96497 2.5 2.16675 5.29822 2.16675 8.75C2.16675 10.4718 2.86295 12.0309 3.98921 13.1613"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>{" "}
                  Export
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {modal && (
        <Success
          showModal={modal}
          onClose={onCloses}
          message={message}
          btnName="Ok"
        />
      )}
    </>
  );
};

export default ExportProspectFile;
