import React from "react";
import Table from "react-bootstrap/Table";
import { formatDate, formatTime } from "../../utils/Validations/ComonFunctions";

const SpendingHistoryTable = ({ handleSort, sortedData, sortBy }) => {
  return (
    <Table>
      <thead
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <tr>
          <th>
            Date{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() => handleSort("date")}
            >
              <path
                d={
                  sortBy.desc
                    ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                    : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                }
                stroke="#667085"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </th>

          <th>
            Time{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() => handleSort("date")}
            >
              <path
                d={
                  sortBy.desc
                    ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                    : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                }
                stroke="#667085"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </th>

          <th>
            Task{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() => handleSort("task")}
            >
              <path
                d={
                  sortBy.desc
                    ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                    : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                }
                stroke="#667085"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </th>

          <th>
            Credits Spent{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() => handleSort("credit_spent")}
            >
              <path
                d={
                  sortBy.desc
                    ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                    : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                }
                stroke="#667085"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </th>

          <th>
            Credits Added{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() => handleSort("credit_added")}
            >
              <path
                d={
                  sortBy.desc
                    ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                    : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                }
                stroke="#667085"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </th>

          <th>
            Credits Balance{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() => handleSort("credit_balanced")}
            >
              <path
                d={
                  sortBy.desc
                    ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                    : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                }
                stroke="#667085"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedData.length > 0 ? (
          sortedData?.map((data, index) => (
            <tr key={index}>
              <td>{formatDate(data?.date)}</td>
              <td>{formatTime(data?.date)}</td>
              <td>{data.task === "ICP" ? "VAIS" : data.task}</td>
              <td>{data?.credit_spent}</td>
              <td>{data?.credit_added ? data?.credit_added : "-"}</td>
              <td>{data?.credit_balanced}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td className="text-center" colSpan={12}>
              No Records Found
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
export default SpendingHistoryTable;
