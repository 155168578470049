import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import slideImage from "../../../assets/images/slide-image.jpg";
import Logo from "../../../assets/images/logo.png";
import "./registration.scss";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { registerValidation } from "../../../utils/Validations/Validations";
import { useDispatch, useSelector } from "react-redux";
import { linkdin, user_signup } from "../../../context/actions/User";
import { capitalize } from "../../../utils/Validations/ComonFunctions";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AuthRedirectHome from "../AuthRedirectHome";
import { hostURl } from "../../../config/http";

const Registration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);

  const initialValues = {
    first_name: "",
    last_name: "",
    phone_number: "",
    company: "",
    password: "",
    re_password: "",
  };

  const getBusinessEmail = useSelector(
    (state) => state?.verifyEmail?.regEmailInfo?.email
  );

  useEffect(() => {
    if (!getBusinessEmail) {
      navigate("/sign-up-email");
    }
  }, [getBusinessEmail]);

  useEffect(() => {
    // Extracting access code from URL parameters when redirected from LinkedIn
    // const params = new URLSearchParams(location.search);
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code) {
      // If access code exists, dispatch action to send it to the backend
      dispatch(
        linkdin(code, (res) => {
          if (res.status === 200) {
            navigate("/dashboard");
          }
        })
      );
    }
  }, [window.location.search]); // Trigger useEffect when location.search changes

  const onSubmit = (values) => {
    let payload = {
      first_name: capitalize(values.first_name),
      last_name: capitalize(values.last_name),
      phone_number: "+" + values.phone_number,
      company: capitalize(values.company),
      password: values.password,
      re_password: values.re_password,
      username: getBusinessEmail || "",
    };

    dispatch(
      user_signup(payload, () => {
        const timerEndTime = new Date().getTime() + 180 * 1000;
        localStorage.setItem("otpTimerEndTime", timerEndTime);
        navigate("/email-verfication");
      })
    );
  };

  const clientId = process.env.REACT_APP_CLIENT_ID
  const redirectUri = `${hostURl}/login`;

  const linkedinAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid,profile,email`;
  return (
    <>
      <AuthRedirectHome />
      <div className="registerPage authPages">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="contentSlider">
                <div className="imageBlock">
                  <img src={slideImage} className="slideImage" alt="" />
                </div>
                <div className="contentBlock text-center">
                  <h2>Welcome Back!</h2>
                  <p>
                    Elevate your business insights with precision. Log in to
                    discover and refine your Ideal Customer Profiles
                    effortlessly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="backLink">
                <Link to="/sign-up-email">
                  <i className="fa fa-angle-left"></i> Back
                </Link>
              </div>
              <div className="formWrapper">
                <div className="siteLogo" style={{ marginBottom: "0px" }}>
                  <img src={Logo} className="logoImage" alt="" />
                </div>
                <h2>Sign Up for Valasys AI</h2>
                <div className="signinButton">
                  <a
                    href={linkedinAuthUrl}
                    className="btn btnPrimaryOutline"
                    target="_self"
                  >
                    <i className="fa fa-linkedin-square"></i> Sign In with
                    LinkedIn
                  </a>
                </div>
                <div className="optionText">
                  <p>Or</p>
                </div>

                <Formik
                  initialValues={initialValues}
                  validationSchema={registerValidation}
                  onSubmit={onSubmit}
                >
                  {({ values, handleChange, handleBlur }) => (
                    <Form className="signUpForm authForm">
                      <div className="row">
                        <div className="form-group col-12 col-md-6">
                          <label>
                            First Name <span className="required">&#42;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="first_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.first_name}
                          />
                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group col-12 col-md-6">
                          <label>
                            Last Name <span className="required">&#42;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="last_name"
                            placeholder="Last Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.last_name}
                          />
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group col-12">
                          <label>
                            Phone number <span className="required">&#42;</span>
                          </label>
                          <Field name="phone_number">
                            {({ field, form }) => (
                              <div>
                                <PhoneInput
                                  inputProps={{
                                    name: "phone_number",
                                    required: true,
                                    autoFocus: true,
                                  }}
                                  country={"US"} // Set your default country
                                  value={field.value}
                                  onChange={(value) =>
                                    form.setFieldValue("phone_number", value)
                                  }
                                />
                                <ErrorMessage
                                  name="phone_number"
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="form-group col-12">
                          <label>
                            Company Name <span className="required">&#42;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="company"
                            placeholder="Company Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.company}
                          />
                          <ErrorMessage
                            name="company"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group col-12">
                          <label>
                            Password <span className="required">&#42;</span>
                          </label>
                          <div className="passwordField">
                            <input
                              type={passwordVisible ? "text" : "password"}
                              className="form-control"
                              placeholder="Password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />

                            <i
                              className={`fa ${
                                passwordVisible ? "fa-eye" : "fa-eye-slash"
                              }`}
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                            ></i>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group col-12">
                          <label>
                            Confirm Password
                            <span className="required">&#42;</span>
                          </label>
                          <div className="passwordField">
                            <input
                              type={
                                confirmPasswordVisible ? "text" : "password"
                              }
                              className="form-control"
                              placeholder="Confirm Password"
                              name="re_password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.re_password}
                            />

                            <i
                              className={`fa ${
                                confirmPasswordVisible
                                  ? "fa-eye"
                                  : "fa-eye-slash"
                              }`}
                              onClick={() =>
                                setConfirmPasswordVisible(
                                  !confirmPasswordVisible
                                )
                              }
                            ></i>
                          </div>
                          <ErrorMessage
                            name="re_password"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group col-12">
                          <p className="requiredInfo">
                            <span className="required">&#42;</span> Required
                            Fields
                          </p>
                        </div>
                        <div className="form-group fieldsGroup col-12">
                          <div className="ploicyTerms">
                            <input
                              type="checkbox"
                              checked={isCheckboxChecked}
                              onChange={() =>
                                setCheckboxChecked(!isCheckboxChecked)
                              }
                            />
                            <span>
                              By clicking Create an account, I agree that I have
                              read and accepted the{" "}
                              <Link to=""> Terms of Use </Link> and{" "}
                              <Link to=""> Privacy Policy </Link>.
                            </span>
                          </div>
                        </div>
                        <div className="form-group formSubmit signinButton col-12">
                          <button
                            type="submit"
                            className="btn"
                            disabled={!isCheckboxChecked}
                          >
                            Create an Account
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>

                <div className="accountInfo">
                  <p className="m0 text-center">
                    Already have an account? <Link to="/login">Log In</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
