import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./DomainModel.scss";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";

const DomainModel = ({ message, btnName, onClose, showModal, data }) => {
  const navigate = useNavigate();

  const downloadExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      ["Invalid Domains"],
      ...data.map((domain) => [domain]),
    ]);
    XLSX.utils.book_append_sheet(wb, ws, "Invalid Domains");
    XLSX.writeFile(wb, "invalid_domains.xlsx");
  };

  return (
    <Modal className="successModal" show={showModal} onHide={onClose} centered>
      <Modal.Body className="modal-body text-center">
        <p>
          <strong>{message}</strong>
        </p>

        <Button
          className="btn"
          onClick={() => {
            onClose();
            downloadExcel();
          }}
          style={{ marginRight: "10px" }}
        >
          Continue
        </Button>
        <Button
          className="btn pl-2"
          onClick={() => {
            onClose();
            downloadExcel();
            navigate("/raise-new-ticket?isdefault");
          }}
        >
          Raise a ticket
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default DomainModel;
