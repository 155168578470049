import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import slideImage from "../../../assets/images/slide-image.jpg";
import Logo from "../../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import OtpInput from "react-otp-input";
import Success from "../../Profile/Modals/Success/Success";
import {
  forgot_password_otp_verify,
  password_reset_otp_sender,
  tokenAndUid,
} from "../../../context/actions/User";
import "./forgotPassword.scss";

const ForgotPasswordEmailVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [navigateTo, setNavigateTo] = useState("");
  const [otp, setOtp] = useState("");
  const time = 180;
  const initialTimerValue = localStorage.getItem("otpTimerEndTime")
    ? Math.ceil(
        (localStorage.getItem("otpTimerEndTime") - new Date().getTime()) / 1000
      )
    : time;
  const [timer, setTimer] = useState(initialTimerValue);
  const [isTimerRunning, setIsTimerRunning] = useState(initialTimerValue > 0);

  useEffect(() => {
    let interval;
    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            setIsTimerRunning(false);
            clearInterval(interval);
            localStorage.removeItem("otpTimerEndTime");
            localStorage.removeItem("otp");
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerRunning]);

  const getEmail = useSelector(
    (state) => state?.verifyEmailOtp?.emailOtp?.email
  );
  const [buttonName, setButtonName] = useState("Resend Otp");
  const initialValues = {
    otp: "",
  };

  useEffect(() => {
    if (isTimerRunning) {
      const timerEndTime = new Date().getTime() + timer * 1000;
      localStorage.setItem("otpTimerEndTime", timerEndTime);
      localStorage.setItem("otp", otp);
    }
  }, [timer, otp, isTimerRunning]);

  const onSubmit = () => {
    const payload = { email: getEmail, otp };
    dispatch(
      forgot_password_otp_verify(payload, (res) => {
        if (res.status === 200) {
          dispatch(tokenAndUid({ token: res.data.token, uid: res.data.uid }));
          setModal(true);
          setMessage(res.message);
          setButtonName("Reset Password");
          setNavigateTo("reset-password");
          clearOtpExpiry();
          // setIsTimerRunning(true);
        } else if (res.status === 400) {
          setModal(true);
          setMessage(res.message);
        }
      })
    );
  };

  const onClose = () => {
    setModal(false);
    if (navigateTo === "reset-password") {
      navigate("/reset-password");
      clearOtpExpiry();
    } else {
      setOtp("");
      dispatch(
        password_reset_otp_sender({ email: getEmail }, (res) => {
          if (res.status === 200) {
            setTimer(180);
            setIsTimerRunning(true);
            localStorage.removeItem("otpTimerEndTime");
            localStorage.removeItem("otp");
          }
        })
      );
    }
  };

  const onResendOtp = () => {
    dispatch(
      password_reset_otp_sender({ email: getEmail }, (res) => {
        if (res.status === 200) {
          setOtp("");
          setTimer(time);
          setIsTimerRunning(true);
          localStorage.removeItem("otpTimerEndTime");
          localStorage.removeItem("otp");
        }
      })
    );
  };

  const clearOtpExpiry = () => {
    setTimer(time);
    setIsTimerRunning(false);
    localStorage.removeItem("otpTimerEndTime");
    localStorage.removeItem("otp");
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <div className="verificationPage authPages">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <div className="contentSlider">
              <div className="imageBlock">
                <img src={slideImage} className="slideImage" alt="" />
              </div>
              <div className="contentBlock text-center">
                <h2>Welcome Back!</h2>
                <p>
                  Elevate your business insights with precision. Log in to
                  discover and refine your Ideal Customer Profiles effortlessly.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="backLink">
              <Link to="/forgot-password">
                <i className="fa fa-angle-left"></i> Back
              </Link>
            </div>

            <div className="formWrapper">
              <div className="siteLogo">
                <img src={Logo} className="logoImage" alt="" />
              </div>
              {isTimerRunning && (
                <div style={{ color: "green", textAlign: "center" }}>
                  OTP Expires in: {formatTime(timer)}
                </div>
              )}
              {!isTimerRunning && !modal && (
                <div style={{ color: "red", textAlign: "center" }}>
                  OTP Expired{" "}
                  <div className="accountInfo">
                    <p className="m0 text-center">
                      <Link style={{ color: "green" }} onClick={onResendOtp}>
                        Resend Otp
                      </Link>
                    </p>
                  </div>
                </div>
              )}

              <h2>Enter Email Verification Code</h2>
              <p className="smText text-center">
                We’ve sent a code to {getEmail}
              </p>

              <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ handleSubmit }) => (
                  <Form className="verificationForm authForm">
                    <div className="row">
                      <div className="form-group validationFields col-12">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={4}
                          isInputNum={true}
                          separator={<span>-</span>}
                          inputStyle="otpInput"
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                      <div className="form-group formSubmit signinButton col-12">
                        <button
                          type="submit"
                          className="btn"
                          onClick={handleSubmit}
                          disabled={otp.length < 4}
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="accountInfo">
                <p className="m0 text-center">
                  Back to{" "}
                  <Link onClick={() => clearOtpExpiry()} to="/">
                    Log In
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <Success
          showModal={modal}
          onClose={onClose}
          message={message}
          btnName={buttonName}
        />
      )}
    </div>
  );
};

export default ForgotPasswordEmailVerification;
