import React, { useState } from "react";
import "./ExportFile.scss";
import * as XLSX from "xlsx";
// import ExcelJS from "exceljs";

import { Button, Modal } from "react-bootstrap";
import successIcon from "../../../../assets/images/successIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { mydownloadlist } from "../../../../context/actions/User";
import Success from "../../../Profile/Modals/Success/Success";
import { useNavigate } from "react-router-dom";
const ExportFile = ({ selectedRows, showModal, onClose, type }) => {
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);

  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const selectedids = selectedRows.map((data) => data.id);
  const staffID = userData.userInfo?.user_staff?.user;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState("");
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  //this navigateto usestate is for setting the navigate value to the login.because if we pass navigate("/login") directly at that time modal is not opening and directly going to the login page . to handle this we use that.
  const [navigateTo, setNavigateTo] = useState("");
  const [exported, setExported] = useState(false); // State to track if exported

  // const handleExport = (e) => {
  //   e.preventDefault();

  //   // Check if fileName is provided before proceeding with export
  //   if (!fileName.trim()) {
  //     // If fileName is not provided, you can handle this case, e.g., show an alert
  //     alert("Please enter a file name before exporting.");
  //     return;
  //   }

  //   const fieldsToExport = icpData
  //     .filter(({ id }) => selectedRows.includes(id))
  //     .map(
  //       ({
  //         company_name,
  //         comp_size,
  //         industry_id,
  //         website,
  //         domain,
  //         country,
  //         revenue,
  //         icp_score,
  //       }) => ({
  //         company_name,
  //         comp_size,
  //         industry_id,
  //         website,
  //         domain,
  //         country,
  //         revenue,
  //         icp_score,
  //       })
  //     );

  //   const ws = XLSX.utils.json_to_sheet(fieldsToExport);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

  //   // Convert workbook to binary string
  //   const binaryString = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  //   // Convert binary string to Blob
  //   const blob = new Blob([s2ab(binaryString)], {
  //     type: "application/octet-stream",
  //   });

  //   // Create FormData to append the Blob and other parameters
  //   const formData = new FormData();
  //   formData.append("file", blob, `${fileName}.xlsx`);
  //   formData.append("user", userId);
  //   formData.append("type", type);
  //   formData.append("filename", fileName);

  //   // Dispatch the action with the FormData payload
  //   dispatch(
  //     mydownloadlist(formData, (res) => {
  //       if (res.status === 200) {
  //         setModal(true);
  //         setMessage(res.message);

  //         setNavigateTo("my-download-list");
  //       } else if (res.status === 400) {
  //         setModal(true);
  //         setMessage(res.message);
  //       }
  //     })
  //   );
  //   // Close the modal
  //   // onClose();
  // };

  // Helper function to convert string to ArrayBuffer
  // const s2ab = (s) => {
  //   const buf = new ArrayBuffer(s.length);
  //   const view = new Uint8Array(buf);
  //   for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  //   return buf;
  // };

  const handleExport = (e) => {
    e.preventDefault();

    let payload = {
      id: selectedids,
      filename: fileName,
      user: userId,
      type: type,
      icp_data: selectedRows,
    };

    // Check if the fileName is provided
    if (!fileName) {
      // Show an error message or handle it accordingly
      setMessage("Please provide a file name.");
      setModal(true);
      return;
    }

    if (!exported) {
      setExported(true); // Set exported to true
      if (staffID) {
        payload = { ...payload, staff_id:staffID };
      }
      // Dispatch an action with the selected IDs and file name as payload
      dispatch(
        mydownloadlist(payload, token, (res) => {
          if (res.status === 200) {
            localStorage.removeItem("icp_payload");
            setModal(true);
            setMessage(res.message);

            setNavigateTo("my-download-list");
          } else if (res.status === 400) {
            setModal(true);
            setMessage(res.message);
            setExported(false);
          }
        })
      );
    }
    // Close the modal
    // onClose();
  };
  const onCloses = () => {
    setModal(false);
    if (navigateTo === "my-download-list") {
      navigate("/my-download-list");
    }
  };
  return (
    <>
      <Modal className="ExportModal" show={showModal} onHide={onClose} centered>
        <Modal.Body className="modal-body text-center">
          <h2>File Name</h2>
          <p>Provide a meaningful name to your export file:</p>
          <form>
            <div className="row">
              <div className="form-group col-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter File Name"
                  name="fileName"
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                />
              </div>
              <div className="form-group buttonGroup m-0 col-12">
                <Button
                  type="button"
                  className="btn btnPrimaryOutline"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="btn"
                  onClick={handleExport}
                  disabled={exported}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M7.16675 14.1667L10.5001 17.5M10.5001 17.5L13.8334 14.1667M10.5001 17.5V10M17.1667 13.9524C18.1847 13.1117 18.8334 11.8399 18.8334 10.4167C18.8334 7.88536 16.7814 5.83333 14.2501 5.83333C14.068 5.83333 13.8976 5.73833 13.8052 5.58145C12.7185 3.73736 10.7121 2.5 8.41675 2.5C4.96497 2.5 2.16675 5.29822 2.16675 8.75C2.16675 10.4718 2.86295 12.0309 3.98921 13.1613"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>{" "}
                  Export
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {modal && (
        <Success
          showModal={modal}
          onClose={onCloses}
          message={message}
          btnName="Ok"
        />
      )}
    </>
  );
};

export default ExportFile;
