import { ErrorMessage, Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import slideImage from "../../../assets/images/slide-image.jpg";
import { reset_password } from "../../../context/actions/User";
import { resetPasswordValidation } from "../../../utils/Validations/Validations";
import Success from "../../Profile/Modals/Success/Success";
import AuthRedirectHome from "../AuthRedirectHome";
const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    password: "",
    re_password: "",
  };
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [navigateTo, setNavigateTo] = useState("");
  const tokenAndUid = useSelector((state) => state?.uidAndToken);

  const onSubmit = (values) => {
    const payload = {
      password: values.password,
      token: tokenAndUid.token,
      uid: tokenAndUid.uid,
    };
    dispatch(
      reset_password(payload, (res) => {
        if (res.status === 200) {
          setModal(true);
          setMessage(res.message);
          setNavigateTo("login");
        } else if (res.status === 400) {
          setModal(true);
          setMessage(res.message);
        }
      })
    );
  };

  const onClose = () => {
    setModal(false);
    if (navigateTo === "login") {
      navigate("/login");
    }
  };

  return (
    <>
      <AuthRedirectHome />
      <div className="ResetPasswordPage authPages">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="contentSlider">
                <div className="imageBlock">
                  <img src={slideImage} className="slideImage" alt="" />
                </div>
                <div className="contentBlock text-center">
                  <h2>Welcome Back!</h2>
                  <p>
                    Elevate your business insights with precision. Log in to
                    discover and refine your Ideal Customer Profiles
                    effortlessly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="formWrapper">
                <div className="siteLogo">
                  <img src={Logo} className="logoImage" alt="" />
                </div>
                <h2>Set New Password</h2>
                <p className="smText text-center">
                  Your new password must be different to previously used
                  passwords.
                </p>

                <Formik
                  initialValues={initialValues}
                  validationSchema={resetPasswordValidation}
                  onSubmit={onSubmit}
                >
                  {({ values, handleChange, handleBlur }) => (
                    <Form className="ResetPasswordForm authForm">
                      <div className="row">
                        <div className="form-group col-12">
                          <label>
                            Password <span className="required">&#42;</span>
                          </label>
                          <div className="passwordField">
                            <input
                              type={passwordVisible ? "text" : "password"}
                              className="form-control"
                              placeholder="Password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />

                            <i
                              className={`fa ${
                                passwordVisible ? "fa-eye" : "fa-eye-slash"
                              }`}
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                            ></i>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group col-12">
                          <label>
                            Confirm Password
                            <span className="required">&#42;</span>
                          </label>
                          <div className="passwordField">
                            <input
                              type={
                                confirmPasswordVisible ? "text" : "password"
                              }
                              className="form-control"
                              placeholder="Confirm Password"
                              name="re_password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.re_password}
                            />
                            <i
                              className={`fa ${
                                confirmPasswordVisible
                                  ? "fa-eye"
                                  : "fa-eye-slash"
                              }`}
                              onClick={() =>
                                setConfirmPasswordVisible(
                                  !confirmPasswordVisible
                                )
                              }
                            ></i>
                          </div>
                          <ErrorMessage
                            name="re_password"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group formSubmit signinButton col-12 mt-2">
                          <button type="submit" className="btn">
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>

                <div className="accountInfo">
                  <p className="m0 text-center">
                    Need Help? <Link to="https://valasys.io/valasysai/troubleshoot#login-issue" target="_blank">Click Here</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {modal && (
          <Success
            showModal={modal}
            onClose={onClose}
            message={message}
            btnName="Login"
          />
        )}
      </div>
    </>
  );
};

export default ResetPassword;
