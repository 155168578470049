import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "./Ticket.scss";
import { formatDate } from "../../../utils/Validations/ComonFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  addComments,
  getAllComments,
  getUserDetails,
  updateTicketImage,
  getTicketByID,
} from "../../../context/actions/User";
import ImgsViewer from "react-images-viewer";
import { hostBackURl } from "../../../config/http";

const Ticket = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const [getUserData, setGetUserData] = useState([]);
  const [comment, setComment] = useState(""); // State variable to hold the user's comment
  const [content, setContent] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [ticketData, setTicketData] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  //below useEffect for getting user details
  useEffect(() => {
    if (userId) {
      const payload = {
        userId: userId,
      };
      dispatch(
        getUserDetails(payload, token, (result) => {
          setGetUserData(result);
        })
      );
      handleTicketByID();
    }
  }, [userId, token, dispatch]);

  const handleTicketByID = () => {
    const payload = {
      ticketId: state?.ticket_id,
    };
    dispatch(
      getTicketByID(payload, token, (result) => {
        setTicketData(result);
        setImageUrl(result?.upload_files);
      })
    );
  };

  useEffect(() => {
    const payload = {
      userId: userId,

      ticket_no: state?.ticket_no,
    };
    setImageUrl(state?.upload_files);
    dispatch(
      getAllComments(payload, token, (result) => {
        setContent(result?.data);
      })
    );
  }, [userId, token, state?.ticket_no, dispatch]);

  const handleCommentChange = (event) => {
    setComment(event.target.value); // Update the comment state as the user types
  };

  const handleSendComment = (e) => {
    e.preventDefault();
    const payload = {
      user_profile_id: userId,
      support_ticket: state?.ticket_id,
      content: comment,
    };

    dispatch(
      addComments(payload, token, (result) => {
        if (result.status === 200) {
          const commentpayload = {
            userId: userId,

            ticket_no: state?.ticket_no,
          };
          dispatch(
            getAllComments(commentpayload, token, (commentsResult) => {
              setContent(commentsResult?.data);
            })
          );
          setComment("");
        } else {
          // Handle error condition if necessary
        }
      })
    );
  };

  const handleImageChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      const validImageTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
      ];
      const validDocumentTypes = [
        "application/vnd.ms-excel", // .xls
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "text/csv", // .csv
      ];
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
  
      if (uploadedFile.size > maxSize) {
        alert("File size exceeds 5MB. Please upload a smaller file.");
        e.target.value = ""; // Reset the input
      } else if (validImageTypes.includes(uploadedFile.type)) {
        setErrorMessage("");
        setSelectedImage(uploadedFile);
        const ticket_id = state?.ticket_id;
        const formData = new FormData();
        formData.append("upload_files", uploadedFile);
  
        dispatch(
          updateTicketImage(ticket_id, formData, token, (result) => {
            setImageUrl(result.upload_files);
            handleTicketByID()
          })
        );
      } else if (validDocumentTypes.includes(uploadedFile.type)) {
        setErrorMessage("");
        // Handle document files as needed
        const ticket_id = state?.ticket_id;
        const formData = new FormData();
        formData.append("upload_files", uploadedFile);
  
        dispatch(
          updateTicketImage(ticket_id, formData, token, (result) => {
            setImageUrl(result.upload_files);
            handleTicketByID()
          })
        );
      } else {
        setErrorMessage("File type not supported. Please upload an image or document file.");
        e.target.value = ""; // Reset the input
      }
    }
  };
  
  const isImageFile = (fileUrl) => {
    const imageExtensions = ["jpeg", "png", "gif", "bmp", "webp", "jpg"];
    // return imageExtensions.some((ext) => fileUrl?.endsWith(`.${ext}`));
    return fileUrl
  };
  const truncateFilename = (filename) => {
    const maxLength = 40;
    // Remove '/media/' from the path
    const pathWithoutMedia = filename?.replace('/media/', '');
    // Truncate filename if it's too long
    if (pathWithoutMedia?.length > maxLength) {
      return pathWithoutMedia?.substring(0, maxLength - 3) + "...";
    }
    return pathWithoutMedia;
  };
  

  return (
    <>
      <div className="pageHeadingBlock SupportTicketsHeading">
        <div className="pageTitle">
          <h3>
            <Link to="/support-tickets" className="backToPage">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M9.13073 15.1663L14.8474 20.8829C15.0807 21.1163 15.1925 21.3885 15.1828 21.6996C15.1731 22.0107 15.0516 22.2829 14.8182 22.5163C14.5849 22.7302 14.3127 22.842 14.0016 22.8517C13.6905 22.8614 13.4182 22.7496 13.1849 22.5163L5.4849 14.8163C5.36823 14.6996 5.28559 14.5732 5.23698 14.4371C5.18837 14.301 5.16406 14.1552 5.16406 13.9996C5.16406 13.8441 5.18837 13.6982 5.23698 13.5621C5.28559 13.426 5.36823 13.2996 5.4849 13.1829L13.1849 5.48294C13.3988 5.26905 13.6661 5.16211 13.987 5.16211C14.3078 5.16211 14.5849 5.26905 14.8182 5.48294C15.0516 5.71628 15.1682 5.99336 15.1682 6.31419C15.1682 6.63503 15.0516 6.91211 14.8182 7.14544L9.13073 12.8329H22.1682C22.4988 12.8329 22.7759 12.9447 22.9995 13.1684C23.2231 13.392 23.3349 13.6691 23.3349 13.9996C23.3349 14.3302 23.2231 14.6072 22.9995 14.8309C22.7759 15.0545 22.4988 15.1663 22.1682 15.1663H9.13073Z"
                  fill="#414141"
                />
              </svg>
            </Link>{" "}
            Ticket id: {ticketData?.ticket_no.split("-")[0]}
          </h3>
        </div>
        <div className="ticketDetails">
          <ul>
            <li>
              <span className="inProcess circle"></span>
              <span className="text">Status</span>
              <span className="status boldValue">{ticketData?.status}</span>
            </li>
            <li>
              <span className="text">Raised on</span>
              <span className="date boldValue">
                {formatDate(ticketData?.date_created)}
              </span>
            </li>
            {/* <li>
              <span className="text">Assigned To</span>
              <span className="assignedName boldValue">Olivia Markov</span>
            </li> */}
            {/* <li>
              <span className="text">Resolved on</span>
              <span className="date boldValue">-</span>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper SingleSupportTicket">
              <div className="SupportTicketDetails">
                <div className="details">
                  <h5>{ticketData?.issue_category}</h5>
                </div>

                <div className="details">
                  <h3>{ticketData?.issue_sub_category}</h3>
                </div>
                <div className="message">
                  <p>{ticketData?.explaination}</p>
                </div>
                {ticketData?.upload_files !== null && (
                  <div className="uploadedDocs">
                    {isImageFile(ticketData?.upload_files) ? (
                      <img
                        src={imageUrl}
                        onClick={() => setIsOpen(true)}
                        style={{ cursor: "pointer" }}
                        alt="Uploaded document"
                      />
                    ) : (
                      <div
                        className="fileDetails"
                        onClick={() =>
                          window.open(
                            ticketData?.upload_files,
                            "_blank"
                          )
                        }
                        style={{ cursor: "pointer",marginRight:"10px" }}
                      >
                        <span>{truncateFilename(ticketData?.upload_files)}</span>
                      </div>
                    )}

                    <label style={{ cursor: "pointer" }} className="editIcon">
                      <input
                        type="file"
                        accept="image/*, .xls, .xlsx, .csv"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-edit"
                      >
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.121 2.121 0 1 1 3 3L7 20l-4 1 1-4L18.5 2.5z"></path>
                      </svg>
                    </label>
                  </div>
                )}
                <span className="error">
                  {errorMessage ? errorMessage : ""}
                </span>
              </div>
              <div className="SupportTicketComments">
                <h5>Comments</h5>
                <form className="commentForm">
                  <div className="form-group userDetails">
                    {getUserData?.profile_image ? (
                      <img src={getUserData?.profile_image} alt="profile" />
                    ) : (
                      <h2
                        className="userNameLetter"
                        style={{
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#F4924A",
                          width: "4.8rem",
                          height: "4.8rem",
                          marginBottom: 0,
                          color: "white",
                          marginRight: "20px",
                        }}
                      >
                        {getUserData?.first_name
                          ? getUserData.first_name.charAt(0).toUpperCase()
                          : null}
                      </h2>
                    )}
                    <p>
                      {getUserData?.first_name} &nbsp; {getUserData?.last_name}
                    </p>
                  </div>
                  <div className="form-group userDetails">
                    {ticketData?.status !== "Resolved" && (
                      <textarea
                        placeholder="Message Here"
                        className="form-control"
                        maxLength="200"
                        value={comment} // Bind the value of the textarea to the state
                        onChange={handleCommentChange} // Handle changes in the textarea
                      ></textarea>
                    )}
                  </div>
                  <div className="btnGroup form-group m-0">
                    {ticketData?.status !== "Resolved" && (
                      <>
                        <button
                          className="btn btnPrimaryOutline"
                          onClick={(e) => {
                            e.preventDefault();
                            setComment("");
                          }}
                          disabled={!comment}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn"
                          onClick={handleSendComment}
                          disabled={!comment}
                        >
                          Send
                        </button>{" "}
                      </>
                    )}
                  </div>
                </form>
                <div className="messagesAdded">
                  {content.map((item, index) => (
                    <div key={index} className="messageBlock">
                      {item?.profile_img ? (
                        <div className="userImage">
                          <img
                            src={item?.profile_img}
                            alt="profile"
                          />
                        </div>
                      ) : (
                        <h2
                          className="userNameLetter"
                          style={{
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#F4924A",
                            width: "4.8rem",
                            height: "4.8rem",
                            marginBottom: 0,
                            color: "white",
                            marginRight: "20px",
                          }}
                        >
                          {item?.first_name
                            ? item.first_name.charAt(0).toUpperCase()
                            : null}
                        </h2>
                      )}
                      <div className="userMessage">
                        <h6>
                          {item?.first_name} &nbsp; {item?.last_name}
                          <span className="messageTime">{item.time_since}</span>
                        </h6>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  ))}

                  {/* <div className="messageBlock">
                    <div className="userImage">
                      <img src={userImage} alt="" />
                    </div>
                    <div className="userMessage">
                      <h6>
                        Olivia Markov{" "}
                        <span className="messageTime">43 min ago</span>
                      </h6>
                      <p>
                        Thank you for reaching out to us. We apologize for any
                        inconvenience you've experienced. Our technical team is
                        investigating the issue you reported with the ICP
                        building process. We appreciate your patience and
                        cooperation.
                      </p>
                      <p>
                        In the meantime, please ensure that your data file
                        adheres to the specified format, and we'll keep you
                        updated on the progress. If there are further details or
                        logs you can provide, it would assist our investigation.
                      </p>
                      <p>Thank you for your understanding.</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="fade-box">
            {isOpen &&
              imageUrl && ( // Ensure isOpen is true and imageUrl is defined
                <ImgsViewer
                  isOpen={isOpen}
                  onClose={() => setIsOpen(false)}
                  imgs={[{ src: imageUrl }]}
                />
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Ticket;
